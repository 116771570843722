import { atomWithBroadcast } from '~shared/atoms/atom-with-broadcast';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import type { ChallengeParameters } from '../api';
import type { UserInfo } from '../auth';
import { isEmployeeRoleAllowed } from '../lib/is-employee-role-allowed';

export const agentPhoneAtom = atomWithStorage<string | null>(
  'merchant_portal_agent_phone',
  null,
  undefined,
  { getOnInit: true }
);

export const userInfoAtom = atomWithBroadcast<UserInfo | null>(
  'merchant_portal_user_info',
  null
);

export const isSessionRestoredAtom = atom(false);

export const isEmployeeRoleAllowedAtom = atom((get) =>
  isEmployeeRoleAllowed(get(userInfoAtom)?.employeeRole)
);

export const isAuthorizedAtom = atomWithBroadcast<boolean | null>(
  'merchant_portal_is_authorized',
  null
);

export const lastInteractionTimeAtom = atomWithBroadcast(
  'merchant_portal_last_interaction_time',
  Date.now()
);

export const employeeIdAtom = atom((get) => get(userInfoAtom)?.employeeId);

export const initiateChallengeParamsAtom =
  atomWithBroadcast<ChallengeParameters | null>(
    'merchant_portal_initiate_challenge_params',
    null
  );
