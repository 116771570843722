import { useEffect } from 'react';
import { useParams, useNavigate, createSearchParams } from 'react-router-dom';

import { FormLoadingPage } from '~widgets/form-loading-page';
import {
  recoverLoanDetailsFormValues,
  formatLoanDetailsFormValues,
  LoanDetailsForm,
  getLoanItemsDetailsList,
} from '~widgets/loan-details-form';

import { useUpdateManufacturerIdsMutation } from '~entities/application';
import { useApplicationVasListMutation } from '~entities/vas';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { FormHeader } from '~shared/ui/form-header';
import { GeneralError } from '~shared/ui/general-error';
import { Screen } from '~shared/ui/screen';

import { useNotifications } from '@pfa/front-notifications';

import { useInitialData, useGenerateAgreement } from './lib';

export function LoanDetailsPage() {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { id = '' } = useParams();

  const initial = useInitialData(id);

  const {
    itemInsuranceList = [],
    loanInsuranceList = [],
    bundleList = [],
  } = initial.data?.availableVasList ?? {};

  const applicationVasListMutation = useApplicationVasListMutation();
  const manufacturerIdsMutation = useUpdateManufacturerIdsMutation();

  const hasNonRetailerZeroRate =
    initial?.data?.applicationDetails.orderItems?.find(
      (item) =>
        !!item.zeroRateItemId &&
        initial?.data?.zeroRateItems?.[item.zeroRateItemId].producerType !==
          'RETAILER'
    );

  const isManufacturerIdsUpdated =
    !hasNonRetailerZeroRate ||
    (!manufacturerIdsMutation.isPending && manufacturerIdsMutation.isSuccess);

  const isVasListUpdated =
    !applicationVasListMutation.isPending &&
    applicationVasListMutation.isSuccess;

  const hasVases = [itemInsuranceList, loanInsuranceList, bundleList].some(
    (list) => list.length > 0
  );

  const hasNoAvailableVas = !!initial.data && !hasVases;

  const loanItems = initial.data
    ? getLoanItemsDetailsList(
        initial.data.applicationDetails.orderItems || [],
        initial.data.availableVasList.itemInsuranceList,
        initial.data.zeroRateItems
      )
    : undefined;

  const generateAgreement = useGenerateAgreement({
    enabled:
      (isVasListUpdated && isManufacturerIdsUpdated) ||
      (hasNoAvailableVas && loanItems?.length === 0),
    applicationId: id,
  });

  const handlePageClose = () => {
    navigate(`/applications/${id}`);
  };

  const handleErrorReload = () => {
    if (initial.refetchFailedRequests) {
      initial.refetchFailedRequests();
    }
    if (hasNoAvailableVas && generateAgreement.isError) {
      generateAgreement.refetch();
    }
  };

  useEffect(() => {
    if (generateAgreement.isSuccess) {
      navigate(
        {
          pathname: `/applications/${id}/agreement-review`,
          search: hasNoAvailableVas
            ? ''
            : createSearchParams({ back: 'loan-details' }).toString(),
        },
        {
          state: {
            vasAvailable: !hasNoAvailableVas,
          },
          replace: true,
        }
      );
    }
  }, [generateAgreement.isSuccess, id, navigate, hasNoAvailableVas]);

  useEffect(() => {
    if (
      generateAgreement.isError ||
      applicationVasListMutation.isError ||
      manufacturerIdsMutation.isError
    ) {
      notifications.error('Error occured. Please try again', {
        showClose: true,
      });
    }
  }, [
    generateAgreement.isError,
    manufacturerIdsMutation.isError,
    applicationVasListMutation.isError,
    notifications,
  ]);

  const showErrorScreen =
    initial.isError || (hasNoAvailableVas && generateAgreement.isError);

  const showLoadingScreen =
    initial.isLoading || (hasNoAvailableVas && generateAgreement.isLoading);

  const showVasForm = hasVases || loanItems?.length;

  if (showLoadingScreen) {
    return <FormLoadingPage />;
  }

  if (showErrorScreen) {
    return (
      <Screen header={<FormHeader onClick={handlePageClose} />}>
        <GeneralError onReload={handleErrorReload} />
      </Screen>
    );
  }

  if (showVasForm) {
    const data = nonNullableValue(initial.data);

    return (
      <LoanDetailsForm
        applicationId={id}
        approvedLoan={nonNullableValue(data.applicationDetails.approvedProduct)}
        loanItemsDetails={nonNullableValue(loanItems)}
        hasVases={hasVases}
        initialValues={recoverLoanDetailsFormValues({
          availableLoanItems: nonNullableValue(loanItems),
          availableVasList: data.availableVasList,
          selectedVasList: data.applicationVasList,
          orderItems: data.applicationDetails.orderItems || [],
        })}
        loanVasList={[
          ...data.availableVasList.loanInsuranceList,
          ...(data.availableVasList.bundleList?.filter(
            ({ displayCategory }) => displayCategory === 'LOAN_INSURANCE'
          ) || []),
        ]}
        onClose={handlePageClose}
        onSubmit={(formValues) => {
          const { vasPayload, manufacturerIdsPayload } =
            formatLoanDetailsFormValues(
              formValues,
              nonNullableValue(loanItems),
              id
            );
          applicationVasListMutation.mutate(vasPayload);
          if (manufacturerIdsPayload.items.length) {
            manufacturerIdsMutation.mutate({
              ...manufacturerIdsPayload,
              applicationId: id,
            });
          }
        }}
        isLoading={
          applicationVasListMutation.isPending ||
          manufacturerIdsMutation.isPending ||
          generateAgreement.isLoading ||
          generateAgreement.isSuccess
        }
      />
    );
  }
  return <></>;
}
