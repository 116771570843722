import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  { value: 'FRIEND', title: 'Friend' },
  { value: 'FAMILY_MEMBER', title: 'Family member' },
  { value: 'CO_WORKER', title: 'Co-worker' },
  { value: 'OTHER', title: 'Other' },
] as const;

export type PersonalReferenceTypeValue = (typeof options)[number]['value'];
export const personalReferenceTypeOptions =
  options as unknown as AbstractOption[];
