import type { AbstractOption } from '~shared/types/abstract-option';

import {
  ReferenceBusinessValue,
  referenceOptionsBusiness,
} from './reference-options-business';
import {
  ReferenceEmployeeValue,
  referenceOptionsEmployee,
} from './reference-options-employee';
import {
  referenceOptionsSelfEmployed,
  ReferenceSelfEmployedValue,
} from './reference-options-self-employed';

import { EmploymentStatusValue } from '../../employment-details/lib/employment-status-options';

export const getReferenceTypeOptions = (
  status: EmploymentStatusValue
): AbstractOption[] => {
  if (status === 'OWN_BUSINESS') {
    return referenceOptionsBusiness;
  }

  if (['DOMESTIC_WORKER', 'CONTRACTUAL_FREELANCER'].includes(status)) {
    return referenceOptionsSelfEmployed;
  }

  return referenceOptionsEmployee;
};

export type ReferenceValue =
  | ReferenceEmployeeValue
  | ReferenceBusinessValue
  | ReferenceSelfEmployedValue;
