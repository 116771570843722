export const applicationStepNames = {
  clientAssessment: 'clientAssessment',
} as const;

export const personDetailsStepNames = {
  profile: 'profile',
  finances: 'finances',
  employmentDetails: 'employmentDetails',
  workReference: 'workReference',
  personalReference: 'personalReference',
  clientPhoto: 'clientPhoto',
} as const;

export const stepNames = {
  ...applicationStepNames,
  ...personDetailsStepNames,
} as const;

export type ApplicationStepName =
  (typeof applicationStepNames)[keyof typeof applicationStepNames];

export type PersonDetailsStepName =
  (typeof personDetailsStepNames)[keyof typeof personDetailsStepNames];

export type StepName = (typeof stepNames)[keyof typeof stepNames];
