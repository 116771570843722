import { Person } from '~entities/person';

import { FormValues } from './form-values';

import { personDetailsStepNames } from '../lib';
import { recoverProfile } from '../profile';

export function recoverPersonScreenValues(person: Person): Partial<FormValues> {
  const recoveredValue = {
    [personDetailsStepNames.profile]: recoverProfile(person) ?? undefined,
  };
  return recoveredValue;
}
