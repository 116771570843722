import { environment } from '~app/environment';

import { makeRequest } from '~shared/scp-client';

import { useQuery } from '@tanstack/react-query';

import { AgentNewsInfoDto } from './api-types';
import { agentsNewsKeys } from './keys';

import { AgentNewsInfo } from '../agent-news';
import { shouldMarkUnread } from '../lib';
import { getItemInfoFromDto } from '../model/get-agent-news-item-from-dto';

const NEWS_REFETCH_INTERVAL = 10 * 60 * 1000;

export const useAgentsNewsList = <TSelect = AgentNewsInfo[]>(options?: {
  select?: (newsList: AgentNewsInfo[]) => TSelect;
}) => {
  return useQuery({
    queryKey: agentsNewsKeys.list(),
    queryFn: async () => {
      const newsItemsDto = await makeRequest<{ data: AgentNewsInfoDto[] }>({
        httpMethod: 'GET',
        url: `${environment.API_HOST}/cms/api/v1/resources/agent-news-items`,
        credentials: 'include',
      }).then((response) => response.data);

      return newsItemsDto.map((item) => getItemInfoFromDto(item));
    },
    retry: 2,
    refetchInterval: NEWS_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    ...options,
  });
};

export const useAgentsNewsListWithUnreadCount = () => {
  return useAgentsNewsList({
    select: (items) => {
      let unreadCount = 0;

      items.forEach((newsItem) => {
        if (shouldMarkUnread(newsItem)) {
          unreadCount++;
        }
      });

      return { items, unreadCount };
    },
  });
};
