import { useQueries } from '@tanstack/react-query';

import { fetchAgentNewsItem } from './get-agent-news-item';
import { fetchSurveyData } from './get-survey';
import { agentsNewsKeys } from './keys';

import { AgentNewsData } from '../agent-news';

export const useBlockingAgentNews = (ids: string[]) => {
  return useQueries({
    queries: ids.map((id) => {
      return {
        queryKey: agentsNewsKeys.blockingNewsItem(id),
        queryFn: async (): Promise<AgentNewsData> => {
          const newsItem = await fetchAgentNewsItem(id);

          if (newsItem.surveyQuestions.length > 0) {
            const surveyData = await fetchSurveyData(id);

            return {
              details: newsItem,
              surveyData,
            };
          }
          return { details: newsItem };
        },
        retry: 1,
      };
    }),
  });
};
