const PHONE_OTP_REQUESTS_STORAGE = 'phone_otp_requests_storage';
const OTP_REQUEST_DELAY_LONG = 5 * 60 * 1000;
const OTP_REQUEST_DELAY_SHORT = 30 * 1000;
const OTP_REQUEST_COUNT_LIMIT = 4;

type OtpRequestsStorage = {
  [phoneNumber: string]: RequestInfo;
};
type RequestInfo = {
  requestsCount: number;
  lastRequestTime: number;
  retryAfter?: number;
};

const getStorageKey = (type: string) => `${PHONE_OTP_REQUESTS_STORAGE}_${type}`;

export type OTP_TYPE = 'login' | 'agreement';

export const getOtpRetryTime = (type: OTP_TYPE, phoneNumber: string) => {
  const { retryAfter } = getOtpLastRequestInfo(type, phoneNumber);

  if (isRetryTimerActive(type, phoneNumber)) {
    return retryAfter;
  }
  setOtpRetryTime(type, phoneNumber, 0);

  return 0;
};

export const getOtpLastRequestInfo = (type: OTP_TYPE, phoneNumber: string) => {
  const storageKey = getStorageKey(type);
  const lastRequestInfo = (
    JSON.parse(
      window.localStorage.getItem(storageKey) || '{}'
    ) as OtpRequestsStorage
  )[phoneNumber];

  if (!lastRequestInfo) {
    return {} as RequestInfo;
  }

  return lastRequestInfo;
};

export const setOtpRetryTime = (
  type: OTP_TYPE,
  phoneNumber: string,
  retryTime: number
) => {
  const storageKey = getStorageKey(type);
  const phoneOtpRequestsStorage = window.localStorage.getItem(storageKey);

  if (phoneOtpRequestsStorage) {
    const previousRequest = (
      JSON.parse(phoneOtpRequestsStorage) as OtpRequestsStorage
    )[phoneNumber];

    window.localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...JSON.parse(phoneOtpRequestsStorage),
        [phoneNumber]: {
          ...previousRequest,
          retryAfter: retryTime * 1000,
        },
      })
    );
  }
};

export const setOtpRequestTime = (
  type: OTP_TYPE,
  phoneNumber: string,
  lastRequestTime: number
): void => {
  const storageKey = getStorageKey(type);

  const phoneOtpRequestsStorage = window.localStorage.getItem(storageKey);
  if (!phoneOtpRequestsStorage) {
    window.localStorage.setItem(
      storageKey,
      JSON.stringify({
        [phoneNumber]: {
          lastRequestTime,
          requestsCount: 1,
        },
      })
    );
  } else {
    const previousRequest = (
      JSON.parse(phoneOtpRequestsStorage) as OtpRequestsStorage
    )[phoneNumber];

    const lastCount =
      previousRequest &&
      new Date().getTime() - previousRequest.lastRequestTime <
        OTP_REQUEST_DELAY_LONG
        ? previousRequest.requestsCount
        : 0;

    window.localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...JSON.parse(phoneOtpRequestsStorage),
        [phoneNumber]: {
          ...previousRequest,
          lastRequestTime,
          requestsCount: (lastCount % OTP_REQUEST_COUNT_LIMIT) + 1,
        },
      })
    );
  }
};

export const deleteOtpRequestTime = (type: OTP_TYPE, phoneNumber: string) => {
  const storageKey = getStorageKey(type);
  const lastInfo = JSON.parse(window.localStorage.getItem(storageKey) || '{}');
  delete lastInfo[phoneNumber];
  window.localStorage.setItem(storageKey, JSON.stringify(lastInfo));
};

export const clearOldRequestsData = (type: OTP_TYPE) => {
  const storageKey = getStorageKey(type);
  const lastInfo = JSON.parse(window.localStorage.getItem(storageKey) || '{}');
  Object.keys(lastInfo).forEach((key) => {
    if (
      lastInfo[key].lastRequestTime + OTP_REQUEST_DELAY_LONG <
      new Date().getTime()
    ) {
      delete lastInfo[key];
    }
  });
  window.localStorage.setItem(storageKey, JSON.stringify(lastInfo));
};

export const getOtpRequestsCount = (type: OTP_TYPE, phoneNumber: string) => {
  const storageKey = getStorageKey(type);
  const lastRequestInfo = (
    JSON.parse(
      window.localStorage.getItem(storageKey) || '{}'
    ) as OtpRequestsStorage
  )[phoneNumber];

  if (!lastRequestInfo) {
    return 0;
  }

  return lastRequestInfo.requestsCount;
};

export const isRetryTimerActive = (type: OTP_TYPE, phoneNumber: string) => {
  const lastRequestInfo = getOtpLastRequestInfo(type, phoneNumber);

  if (lastRequestInfo.retryAfter) {
    const allowedTime =
      lastRequestInfo.lastRequestTime + lastRequestInfo.retryAfter;

    const delay = allowedTime - new Date().getTime();

    return delay > 0;
  }
};

export const isAttemptsLimitReached = (type: OTP_TYPE, phoneNumber: string) => {
  return (
    isRetryTimerActive(type, phoneNumber) ||
    getOtpRequestsCount(type, phoneNumber) >= OTP_REQUEST_COUNT_LIMIT
  );
};

export const getOtpRequestDelaySeconds = (
  type: OTP_TYPE,
  phoneNumber: string,
  useRetryAfter?: boolean
) => {
  const storageKey = getStorageKey(type);
  const lastRequestInfo = JSON.parse(
    window.localStorage.getItem(storageKey) || '{}'
  )[phoneNumber];

  if (!lastRequestInfo) {
    return 0;
  }

  let delayTime;

  if (useRetryAfter) {
    delayTime = lastRequestInfo.retryAfter || OTP_REQUEST_DELAY_SHORT;
  } else {
    delayTime =
      lastRequestInfo.requestsCount < OTP_REQUEST_COUNT_LIMIT
        ? OTP_REQUEST_DELAY_SHORT
        : OTP_REQUEST_DELAY_LONG;
  }

  const allowedTime = lastRequestInfo.lastRequestTime + delayTime;

  const delay = allowedTime - new Date().getTime();
  if (delay <= 0) {
    return 0;
  }

  return Math.floor(delay / 1000);
};
