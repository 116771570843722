import { environment } from '~app/environment';

import { makeRequest } from '~shared/scp-client';

import { ResponseSuccess } from '@breezeventures/scp-client';
import { skipToken, useQuery } from '@tanstack/react-query';

import { agentsNewsKeys } from './keys';

import { AgentSurveyData } from '../agent-news';

export async function fetchSurveyData(id: string) {
  const response = await makeRequest<ResponseSuccess<AgentSurveyData>>({
    url: `${environment.API_HOST}/agent-portal/api/v1/public/survey/result/get-by-id`,
    payload: { newsId: id },
    credentials: 'include',
  });

  return response.payload;
}

export const useSurvey = (id: string | null) => {
  return useQuery({
    queryKey: agentsNewsKeys.surveyItem(id),
    queryFn: id ? () => fetchSurveyData(id) : skipToken,
    retry: 2,
    gcTime: 0,
  });
};
