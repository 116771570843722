import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { AuthOtpResponse, ChallengeParameters } from '~entities/auth';
import { ConfirmationInput } from '~entities/auth/ui';

import { isTooManyRequestsError } from '~shared/errors';

import isNil from '@tinkoff/utils/is/nil';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Box, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import Tooltip from '@breeze-platform-ui/tooltip';
import { useMachine } from '@xstate/react';

import { ProblemsTooltip } from './problems-tooltip/problems-tooltip';
import { ResendButton } from './resend-button';

import { getMaskedPhone, deleteOtpRequestTime, type OTP_TYPE } from '../../lib';
import { confirmationStatesMachine } from '../../model';
import { AuthorizationError } from '../error';

type FormValues = {
  confirmationCode: string;
};

type Props = {
  phoneNumber: string;
  confirmationType: OTP_TYPE;
  isConfirmationError: (error: Error) => boolean;
  isAuthorizationError: (error: Error) => boolean;
  isAttemptsLimitError: (error: Error) => boolean;
  onResend: () => Promise<void> | Promise<AuthOtpResponse>;
  onCodeSubmit: (code: string) => Promise<void>;
  onSuccess: () => void;
  customAttemptsError?: ReactNode;
  onReachAttemptsLimit?: () => void;
  withAttempts?: boolean;
  confirmationParams?: ChallengeParameters;
  disableRetryAfter?: boolean;
};

export function OtpConfirmation({
  phoneNumber,
  confirmationType,
  isConfirmationError,
  isAuthorizationError,
  isAttemptsLimitError,
  onResend,
  onCodeSubmit,
  onSuccess,
  customAttemptsError,
  onReachAttemptsLimit,
  withAttempts,
  confirmationParams,
  disableRetryAfter,
}: Props): JSX.Element {
  const [onSuccessCalled, setOnSuccessCalled] = useState(false);
  const [state, send] = useMachine(confirmationStatesMachine, {
    services: {
      respond: async (_context, event) => {
        if (event.type === 'ANSWER') {
          return onCodeSubmit(event.value);
        }
      },
    },
    guards: {
      isConfirmationError: ({ error }) =>
        !isNil(error) && isConfirmationError(error),
      isAttemptsLimitError: ({ error }) =>
        !isNil(error) && isAttemptsLimitError(error),
      isAuthorizationError: ({ error }) =>
        !isNil(error) && isAuthorizationError(error),
    },
  });

  useEffect(() => {
    if (state.matches('confirmed') && !onSuccessCalled) {
      setOnSuccessCalled(true);
      deleteOtpRequestTime(confirmationType, phoneNumber);
      onSuccess();
    }
  }, [state, confirmationType, onSuccess, onSuccessCalled, phoneNumber]);

  const maskedPhone = useMemo(() => getMaskedPhone(phoneNumber), [phoneNumber]);

  const onCodeChange = () => {
    send({ type: 'CODE_CHANGE' });
  };

  const onSubmit = (values: FormValues) => {
    send({ type: 'ANSWER', value: values.confirmationCode });
  };

  const { control, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    shouldUnregister: true,
  });

  const shouldDisableActions =
    state.matches('confirmation') || state.matches('confirmed');

  let confirmationError: React.ReactNode;

  if (state.matches('confirmationError')) {
    const attemptText =
      state.context.parameters?.remainingOtpEnterAttempts === 1
        ? 'attempt'
        : 'attempts';
    confirmationError = `Wrong code ${withAttempts ? `(${state.context.parameters?.remainingOtpEnterAttempts} ${attemptText} left)` : ''}`;
  }
  if (state.matches('attemptsLimitError')) {
    onReachAttemptsLimit?.();
    confirmationError = (
      <span>
        You have exceeded the&nbsp;maximum number of&nbsp;attempts. Please,
        request a&nbsp;new code
      </span>
    );
  }
  if (state.matches('unexpectedError')) {
    confirmationError = <span>Error checking the&nbsp;code</span>;
  }
  if (state.matches('attemptsLimitError') && customAttemptsError) {
    return <>{customAttemptsError}</>;
  }

  if (state.matches('authorizationError')) {
    return <AuthorizationError />;
  }

  return (
    <Col gaps={16} alignCross="stretch">
      <Text color="rgba(0, 0, 0, 0.8)" size={15}>
        We sent a&nbsp;confirmation code to{' '}
        <Text display="inline" whiteSpace="nowrap">
          {maskedPhone}
        </Text>
        . Check your&nbsp;Viber
      </Text>
      <Box width={228}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="confirmationCode"
            control={control}
            rules={{
              required: 'Please, enter the code',
              pattern: {
                value: /^\d{6}$/,
                message: 'The code should have 6 digits',
              },
            }}
            render={({ field, ...props }) => (
              <ConfirmationInput
                {...props}
                dataQaType="confirmationCode"
                field={{
                  ...field,
                  onChange: (...args) => {
                    onCodeChange();
                    field.onChange(...args);
                  },
                }}
                label="The code"
                autoComplete="off"
                disabled={state.matches('confirmation')}
                confirmed={state.matches('confirmed')}
                confirmationError={confirmationError}
                onFieldDone={(_event, { value }) =>
                  send({ type: 'ANSWER', value })
                }
              />
            )}
          />
        </form>
      </Box>
      <Col gaps={16} alignCross="start">
        <ResendButton
          confirmationParams={confirmationParams}
          phoneNumber={phoneNumber}
          isAttemptsLimitError={isTooManyRequestsError}
          confirmationType={confirmationType}
          withAttempts={withAttempts}
          disabled={shouldDisableActions}
          disableRetryAfter={disableRetryAfter}
          onResend={onResend}
          onAuthError={() =>
            send({ type: 'ANSWER', value: 'authorizationError' })
          }
          isAuthorizationError={isAuthorizationError}
        />
        <Tooltip
          popoverContent={<ProblemsTooltip />}
          theme="dark"
          align="start"
          hideOnClickOutside
          hideOnTouchMove
          toggleOnClick
        >
          <ButtonLink
            size="s"
            disableHorizontalPadding
            disableVerticalPadding
            disabled={shouldDisableActions}
          >
            I haven’t received the&nbsp;code
          </ButtonLink>
        </Tooltip>
      </Col>
    </Col>
  );
}
