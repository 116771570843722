import { useEffect, useRef } from 'react';

import * as Sentry from '@breeze/web-lib-sentry';

export function useFormRenderTime(isFormLoading: boolean) {
  const formRenderTimeRef = useRef<Partial<{ start: number; end: number }>>({});

  return useEffect(() => {
    if (formRenderTimeRef.current.end) {
      return;
    }

    if (isFormLoading && !formRenderTimeRef.current.start) {
      formRenderTimeRef.current.start = +new Date();
      return;
    }

    formRenderTimeRef.current.end = +new Date();
    const formRenderTime =
      formRenderTimeRef.current.end -
      (formRenderTimeRef.current.start as number);
    Sentry.setMeasurement(
      'ui.applicationForm.render',
      formRenderTime / 1000,
      'second'
    );
  }, [isFormLoading]);
}
