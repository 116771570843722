import type {
  PersonPatchFormatter,
  PersonPatchScreensMapping,
} from '~entities/person/lib';
import { patchFormatValuesByMapping } from '~entities/person/lib';

import { WorkReferenceFormValues } from '../model/work-reference';

const workReferenceMapping: PersonPatchScreensMapping<WorkReferenceFormValues> =
  {
    reference: { path: ['workReference', 'reference'] },
    referenceName: { path: ['workReference', 'referenceName'] },
    workPhone: { path: ['workReference', 'workPhone'] },
  };

export const patchFormatWorkReference: PersonPatchFormatter<
  WorkReferenceFormValues
> = (person, screenValues) => {
  return patchFormatValuesByMapping(person, screenValues, workReferenceMapping);
};
