import { ChallengeParameters, GatewayOtpResponse } from '~entities/auth';

import { HttpError } from '~shared/errors';

import { createMachine, assign } from 'xstate';

interface ResendContext {
  error?: Error | HttpError;
  parameters?: ChallengeParameters;
}

type ResendEvent = { type: 'RESEND_CODE' } | { type: 'COUNTDOWN_OVER' };

type ResendTypestate =
  | {
      value: 'idle';
      context: ResendContext & { error: undefined };
    }
  | {
      value: 'loading';
      context: ResendContext & { error: undefined };
    }
  | {
      value: 'countdown';
      context: ResendContext & { error: undefined };
    }
  | {
      value: 'error';
      context: ResendContext & { error: Error };
    }
  | {
      value: 'unexpectedError';
      context: ResendContext & { error: Error };
    }
  | {
      value: 'authorizationError';
      context: ResendContext & { error: Error };
    }
  | {
      value: 'handleAttemptsLimitError';
      context: ResendContext & { error: Error };
    };

export const resendingStatesMachine = createMachine<
  ResendContext,
  ResendEvent,
  ResendTypestate
>({
  schema: {
    services: {} as {
      resend: { data: undefined };
    },
  },
  predictableActionArguments: true,
  id: 'resending-process',
  initial: 'idle',
  context: { error: undefined },
  states: {
    idle: {
      entry: assign({ error: undefined }),
      on: { RESEND_CODE: { target: 'loading' } },
    },
    loading: {
      invoke: {
        src: 'resend',
        onDone: {
          target: 'countdown',
          actions: assign<
            ResendContext,
            { type: string; data: GatewayOtpResponse }
          >({
            parameters: (_context, event) => event.data?.challengeParameters,
          }),
        },
        onError: {
          target: 'error',
          actions: assign<ResendContext, { type: string; data: Error }>({
            error: (_context, event) => event.data,
          }),
        },
      },
    },
    countdown: {
      entry: ['startCountdown'],
      on: {
        COUNTDOWN_OVER: { target: 'idle' },
      },
    },
    error: {
      always: [
        {
          target: 'authorizationError',
          cond: 'isAuthorizationError',
        },
        { target: 'attemptsLimitError', cond: 'isAttemptsLimitError' },
        { target: 'unexpectedError' },
      ],
    },
    attemptsLimitError: {
      entry: ['handleAttemptsLimitError'],
      on: {
        RESEND_CODE: { target: 'loading' },
      },
    },
    unexpectedError: {
      on: {
        RESEND_CODE: { target: 'loading' },
      },
    },
    authorizationError: {},
  },
});
