import { useCallback, useState } from 'react';
import type { Validate } from 'react-hook-form';

import type { PhoneType } from '~entities/person';

import isEmpty from '@tinkoff/utils/is/empty';
import isString from '@tinkoff/utils/is/string';

type PhoneValues = Partial<Record<PhoneType, string | undefined>>;

function phoneInUse<TFormValues>(
  currentType: PhoneType,
  phones: PhoneValues
): Validate<unknown, TFormValues> {
  return function phoneInUseValidator(currentPhone) {
    if (isEmpty(currentPhone) || !isString(currentPhone)) {
      return undefined;
    }

    const phoneExists = Object.entries(phones).some(
      ([type, phone]) => phone === currentPhone && type !== currentType
    );

    return phoneExists ? 'This phone number is already in use' : undefined;
  };
}

export function usePhonesUnicityValidation(initialValue: PhoneValues) {
  const [phones, setPhones] = useState<PhoneValues>(initialValue);

  const handlePhoneNumberUpdate = (
    value: string | undefined,
    type: PhoneType
  ) => {
    if (phones[type] !== value) {
      setPhones((prevPhones) => ({ ...prevPhones, [type]: value }));
    }
  };

  const validatePhone = useCallback(
    (type: PhoneType) => phoneInUse(type, phones),
    [phones]
  );

  return { handlePhoneUpdate: handlePhoneNumberUpdate, validatePhone };
}
