import type {
  PersonPatchFormatter,
  PersonPatchScreensMapping,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person/lib';
import {
  patchFormatValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person/lib';

import { formatDate, recoverDate } from '~shared/formatters';

import { ProfileFormValues } from '../model/form-values';

const profileFormattingMapping: PersonPatchScreensMapping<ProfileFormValues> = {
  firstName: { path: ['profile', 'firstName'] },
  lastName: { path: ['profile', 'lastName'] },
  middleName: { path: ['profile', 'middleName'] },
  birthDate: {
    path: ['profile', 'birthDate'],
    format: formatDate,
  },
  email: { path: ['profile', 'email'] },
  preferredName: { path: ['profile', 'preferredName'] },
};

export const patchFormatProfile: PersonPatchFormatter<ProfileFormValues> = (
  person,
  screenValues
) => {
  return patchFormatValuesByMapping(
    person,
    screenValues,
    profileFormattingMapping
  );
};

const profileRecoveryMapping: PersonScreensMapping<ProfileFormValues> = {
  firstName: { path: ['name', 'firstName'] },
  lastName: { path: ['name', 'lastName'] },
  middleName: { path: ['name', 'middleName'] },
  birthDate: {
    path: ['birthDate'],
    recover: recoverDate,
  },
  email: { path: ['email'] },
  preferredName: { path: ['name', 'preferredName'] },
};

export const recoverProfile: PersonRecovery<ProfileFormValues> = (person) => {
  return recoverPersonValuesByMapping(person, profileRecoveryMapping);
};
