import { recoverClientAssessment } from '~widgets/client-assessment';
import { recoverAdditionalIdDocument } from '~widgets/loan-application-form/additional-id-document';
import { recoverGCashDetails } from '~widgets/loan-application-form/gcash-details';
import { recoverIdDocument } from '~widgets/loan-application-form/id-document';
import { recoverIdDocumentType } from '~widgets/loan-application-form/id-document-type';

import type { Application, ApplicationRecovery } from '~entities/application';
import type { Person, PersonRecovery } from '~entities/person';

import identity from '@tinkoff/utils/function/identity';

import type {
  PersonDetailsStepName,
  ApplicationStepName,
  StepName,
} from './step-names';
import { personDetailsStepNames, applicationStepNames } from './step-names';

type ApplicationRecoveries = {
  [step in ApplicationStepName]: ApplicationRecovery<any>;
};

type PersonDetailsRecoveries = {
  [step in PersonDetailsStepName]: PersonRecovery<any>;
};

type ScreensValues = Record<StepName, any>;

const applicationRecoveries: ApplicationRecoveries = {
  [applicationStepNames.clientAssessment]: recoverClientAssessment,
};

const personRecoveries: PersonDetailsRecoveries = {
  [personDetailsStepNames.idDocumentType]: recoverIdDocumentType,
  [personDetailsStepNames.idDocument]: recoverIdDocument,
  [personDetailsStepNames.additionalIdDocument]: recoverAdditionalIdDocument,
  [personDetailsStepNames.clientPhoto]: identity,
  [personDetailsStepNames.gCashDetails]: recoverGCashDetails,
};

export function recoverApplicationScreenValues(application: Application) {
  return (Object.keys(applicationRecoveries) as ApplicationStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = applicationRecoveries[stepName];
      acc[stepName] = screenRecovery(application);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}

export function recoverPersonScreenValues(person: Person) {
  return (Object.keys(personRecoveries) as PersonDetailsStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = personRecoveries[stepName];
      acc[stepName] = screenRecovery(person);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}
