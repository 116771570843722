import { environment } from '~app/environment';

import { makeRequest } from '~shared/scp-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { markReadNewsInQueryCache } from '../lib';

export const useReadAgentNews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => {
      return makeRequest({
        url: `${environment.API_HOST}/agent-portal/api/v1/public/news/mark-read`,
        credentials: 'include',
        payload: ids,
      });
    },
    onSuccess(_response, ids) {
      markReadNewsInQueryCache(queryClient, ids);
    },
    retry: 4,
  });
};
