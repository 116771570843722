import type { UpdateManufacturerIdsPayload } from '~entities/application';
import type { OrderItem } from '~entities/order-item';
import {
  type AvailableVasList,
  type ApplicationVasList,
  type SelectedItemInsurance,
  type SelectedLoanInsurance,
  type AvailableLoanInsurance,
  type UpdateApplicationVasListPayload,
  type AvailableVasBundle,
  type SelectedBundleVas,
} from '~entities/vas';

import trim from '@tinkoff/utils/string/trim';

import { getPriceFromValueWithCurrency } from './get-price-from-value-with-currency';

import type {
  ItemDetailsFormValues,
  LoanDetailsFormValues,
  LoanItemInfo,
  LoanVasInsuranceType,
} from '../types';

// As loan item details form is an array of items - the order of initial values is important
// loanItemsInfo is an array of available details - items with VAS + remaining zero rate items, initial values should be set for this array
const recoverItemsDetailsFormValues = ({
  availableLoanItems,
  selectedItemVasList,
  orderItems,
}: {
  availableLoanItems: LoanItemInfo[];
  selectedItemVasList: SelectedItemInsurance[];
  orderItems: OrderItem[];
}): {
  itemDetailsFormValues: ItemDetailsFormValues[];
  itemsVasMonthlyPayment: number;
} => {
  const selectedVasWithVisitedFlag: (SelectedItemInsurance & {
    isVisited?: boolean;
  })[] = selectedItemVasList.map((item) => ({ ...item }));

  const orderItemsWithVisitedFlag: (OrderItem & {
    isVisited?: boolean;
  })[] = orderItems.map((item) => ({ ...item }));

  let itemsVasMonthlyPayment = 0;

  const itemDetailsFormValues = availableLoanItems.map((item) => {
    const recoveredItem: ItemDetailsFormValues = {
      name: item.name,
      vasCatalogId: '',
      itemManufacturerId: '',
    };

    // Find saved VAS for an item
    const savedVasItemIndex = item.vasList.length
      ? selectedVasWithVisitedFlag.findIndex(
          ({ itemCategory, itemName, itemPrice, isVisited }) =>
            !isVisited &&
            item.category === itemCategory &&
            item.name === itemName &&
            +item.price === itemPrice
        )
      : -1;
    if (savedVasItemIndex > -1) {
      // Recover vasCatalogId and itemManufacturerId for the item
      const savedItem = selectedVasWithVisitedFlag[savedVasItemIndex];
      savedItem.isVisited = true;

      const vas = item.vasList.find(({ id }) => savedItem.vasCatalogId === id);
      if (vas) {
        recoveredItem.vasCatalogId = savedItem.vasCatalogId;
        itemsVasMonthlyPayment += getPriceFromValueWithCurrency(
          vas.monthlyPayment
        );
      }
      if (!item.zeroRateItemId) {
        recoveredItem.itemManufacturerId = savedItem.itemManufacturerId;
      }
    }

    // for zero rate items itemManufacturerId from order items response is used
    if (item.zeroRateItemId) {
      // Find saved itemManufacturerId for remaining zero-rate items
      const savedOrderItemIndex = orderItemsWithVisitedFlag.findIndex(
        ({ zeroRateItemId, isVisited }) =>
          !isVisited && item.zeroRateItemId === zeroRateItemId
      );

      if (savedOrderItemIndex > -1) {
        const savedItem = orderItemsWithVisitedFlag[savedOrderItemIndex];
        savedItem.isVisited = true;
        recoveredItem.itemManufacturerId =
          savedItem.zeroRateItemManufacturerId || '';
      }
    }

    return recoveredItem;
  });
  return {
    itemDetailsFormValues,
    itemsVasMonthlyPayment,
  };
};

const recoverLoanVasFormValues = ({
  availableLoanVasList,
  availableVasBundleList = [],
  selectedLoanVasList,
  selectedBundleList = [],
}: {
  availableLoanVasList: AvailableLoanInsurance[];
  availableVasBundleList?: AvailableVasBundle[];
  selectedLoanVasList: SelectedLoanInsurance[];
  selectedBundleList?: SelectedBundleVas[];
}): {
  loanVas?: string;
  loanVasType?: LoanVasInsuranceType;
  loanVasMonthlyPayment: number;
} => {
  const selectedLoanVasItemId = selectedLoanVasList[0]?.vasCatalogId;
  const availableVas = availableLoanVasList.find(
    ({ id }) => id === selectedLoanVasItemId
  );
  if (selectedLoanVasItemId && availableVas) {
    return {
      loanVas: selectedLoanVasItemId,
      loanVasType: 'single',
      loanVasMonthlyPayment: getPriceFromValueWithCurrency(
        availableVas.monthlyPayment
      ),
    };
  }

  // currently there is no identifier for loan/item bundle type on BE, only loan vases are returned here
  const selectedLoanVasBundleId = selectedBundleList[0]?.vasBundleCatalogId;
  const availableVasBundle = availableVasBundleList.find(
    ({ id }) => id === selectedLoanVasBundleId
  );
  if (selectedLoanVasBundleId && availableVasBundle) {
    return {
      loanVas: selectedLoanVasBundleId,
      loanVasType: 'bundle',
      loanVasMonthlyPayment: getPriceFromValueWithCurrency(
        availableVasBundle.monthlyPayment
      ),
    };
  }
  return {
    loanVas: undefined,
    loanVasType: undefined,
    loanVasMonthlyPayment: 0,
  };
};

export const recoverLoanDetailsFormValues = ({
  availableVasList,
  availableLoanItems,
  orderItems,
  selectedVasList,
}: {
  availableVasList: AvailableVasList;
  availableLoanItems: LoanItemInfo[];
  orderItems: OrderItem[];
  selectedVasList: ApplicationVasList;
}): LoanDetailsFormValues => {
  const recoveredItemsDetails = recoverItemsDetailsFormValues({
    availableLoanItems,
    selectedItemVasList: selectedVasList.vasItemInsuranceList,
    orderItems,
  });
  const recoveredLoanVasValues = recoverLoanVasFormValues({
    availableLoanVasList: availableVasList.loanInsuranceList,
    availableVasBundleList: availableVasList.bundleList,
    selectedLoanVasList: selectedVasList.vasLoanInsuranceList,
    selectedBundleList: selectedVasList.vasBundleList,
  });
  return {
    itemsDetails: recoveredItemsDetails.itemDetailsFormValues,
    vasLoanInsurance: recoveredLoanVasValues.loanVas,
    vasLoanInsuranceType: recoveredLoanVasValues.loanVasType,
    totalVasMonthlyPayment:
      recoveredItemsDetails.itemsVasMonthlyPayment +
      recoveredLoanVasValues.loanVasMonthlyPayment,
  };
};

export const formatLoanDetailsFormValues = (
  formValues: LoanDetailsFormValues,
  availableLoanItems: LoanItemInfo[],
  applicationId: string
): {
  vasPayload: UpdateApplicationVasListPayload;
  manufacturerIdsPayload: UpdateManufacturerIdsPayload;
} => {
  const itemDetails = formValues.itemsDetails;
  const vasItemInsuranceList: SelectedItemInsurance[] = [];
  const vasLoanInsuranceList: SelectedLoanInsurance[] = [];
  const vasBundleList: SelectedBundleVas[] = [];
  const manufacturerIdsPayload: UpdateManufacturerIdsPayload = { items: [] };

  itemDetails.forEach((item, index) => {
    if (item.vasCatalogId) {
      const itemInfo = availableLoanItems[index];
      const itemManufacturerId = trim(item.itemManufacturerId) || undefined;
      vasItemInsuranceList.push({
        itemName: itemInfo.name,
        itemCategory: itemInfo.category,
        itemPrice: +itemInfo.price,
        itemManufacturerId,
        vasCatalogId: item.vasCatalogId,
        orderItemId: itemInfo.orderItemId,
      });
    }
    if (availableLoanItems[index]?.zeroRateItemId && item.itemManufacturerId) {
      manufacturerIdsPayload.items.push({
        zeroRateItemId: availableLoanItems[index].zeroRateItemId as string,
        zeroRateItemManufacturerId: item.itemManufacturerId,
      });
    }
  });
  if (
    formValues.vasLoanInsuranceType === 'single' &&
    formValues.vasLoanInsurance
  ) {
    vasLoanInsuranceList.push({
      vasCatalogId: formValues.vasLoanInsurance,
    });
  }
  if (
    formValues.vasLoanInsuranceType === 'bundle' &&
    formValues.vasLoanInsurance
  ) {
    vasBundleList.push({
      vasBundleCatalogId: formValues.vasLoanInsurance,
    });
  }

  return {
    vasPayload: {
      applicationId,
      vasItemInsuranceList,
      vasLoanInsuranceList,
      vasBundleList,
    },
    manufacturerIdsPayload,
  };
};
