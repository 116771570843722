import { useState } from 'react';

import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { ConfirmationState } from '~features/auth/otp';
import { OTPAttemptsError } from '~features/auth/otp/ui/otp-confirmation';

import {
  ChallengeParameters,
  isAttemptsLimitError,
  isChallengeConfirmationError,
} from '~entities/auth';
import { getDeviceMetadata } from '~entities/device-metadata';

import { isAuthError } from '~shared/errors';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';

interface Props {
  confirmationParams?: ChallengeParameters;
  phoneNumber: string;
  onSuccess: () => void;
}

export const AgentSmsConfirmation = (props: Props) => {
  const { phoneNumber, onSuccess, confirmationParams } = props;
  const { authApi } = useDi();
  const tracking = useTracking();
  const [headerText, setHeaderText] = useState('Enter the code');
  return (
    <Screen header={<FormHeader href="/" text={headerText} />}>
      <ConfirmationState
        confirmationParams={confirmationParams}
        confirmationType="login"
        phoneNumber={phoneNumber}
        withAttempts
        isAuthorizationError={isAuthError}
        isConfirmationError={isChallengeConfirmationError}
        isAttemptsLimitError={isAttemptsLimitError}
        onReachAttemptsLimit={() => setHeaderText('')}
        customAttemptsError={<OTPAttemptsError />}
        onCodeSubmit={async (code) => {
          await authApi.respond({
            answer: code,
            userMetadata: {
              webFingerprint: getDeviceMetadata(tracking?.state),
            },
          });
        }}
        onResend={async () => await authApi.resendConfirmation()}
        onSuccess={onSuccess}
      />
    </Screen>
  );
};
