import { useMemo, useEffect, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { NewsBlockingScreen } from '~widgets/agent-news-blocking';

import { AuthGuard } from '~features/auth/guard';
import { UserActivityGuard } from '~features/auth/guard';
import { SessionInitializerWrapper } from '~features/auth/guard';
import { ExplainMissedCheckoutGuardHOC } from '~features/explain-missed-checkout/explain-missed-checkout-guard';
import { PosInitializerWrapper } from '~features/select-pos/guard/pos-initializer';

import { gatewayAddressSuggestionApi } from '~entities/address/api/gateway-address-suggestions-api';
import { gatewayAgentIncentiveApi } from '~entities/agent-incentive';
import {
  confirmationServiceApi,
  gatewayAgreementApi,
} from '~entities/agreement';
import {
  amplitudeAnalytics,
  amplitudeCalculatorAnalytics,
  amplitudeCustomerAnalytics,
} from '~entities/amplitude-analytics';
import { gatewayApplicationApi } from '~entities/application';
import { gatewayAuthApi } from '~entities/auth';
import { deviceLocation } from '~entities/device-metadata';
import { gatewayDocumentApi } from '~entities/document';
import { scpAgreementGenerationApi } from '~entities/document';
import { gatewayFileUploadApi } from '~entities/file';
import { logger } from '~entities/logger';
import { gatewayOfferApi } from '~entities/offer';
import { gatewayPersonApi } from '~entities/person';
import { gatewayPosApi } from '~entities/pos';
import { gatewayProductApi } from '~entities/product';
import { gatewayVasApi } from '~entities/vas';

import type { Tracking } from '@breeze/web-analytics';
import { findOrCreateWuid } from '@breeze/web-lib-wuid';
import { Notifications, NotificationsProvider } from '@pfa/front-notifications';
import { Provider } from 'jotai';

import { environment } from './environment';
import {
  AnalyticsProvider,
  CustomerAnalyticsProvider,
  CalculatorAnalyticsProvider,
} from './providers/analytics-provider';
import { DiProvider } from './providers/di-provider';
import { OpeningTimeProvider } from './providers/opening-time-provider';
import { PortalConfigProvider } from './providers/portal-config-provider';
import { PortalQueryClientProvider } from './providers/portal-query-client-provider';
import { TrackingProvider } from './providers/tracking-provider';

export default function Layout() {
  const openingTime = useRef(new Date().getTime());
  const [tracking, setTracking] = useState<Tracking>();
  useEffect(() => {
    deviceLocation.initialize();
    window.clarity?.('set', 'userId', findOrCreateWuid());
    window.clarity?.('set', 'app_version', environment.VERSION);
  }, []);

  useEffect(() => {
    let triesCount = 0;
    let timerId: NodeJS.Timeout;
    const getTracking = () => {
      triesCount++;
      if (window.tracking) {
        setTracking(window.tracking);
        return;
      }
      if (triesCount > 20) {
        logger('tracking-provider').error('Failed to get tracking');
        return;
      }
      timerId = setTimeout(() => {
        getTracking();
      }, 300);
    };
    getTracking();
    return () => timerId && clearTimeout(timerId);
  }, []);

  const di = useMemo(
    () => ({
      authApi: gatewayAuthApi,
      vasApi: gatewayVasApi,
      posApi: gatewayPosApi,
      personApi: gatewayPersonApi,
      productApi: gatewayProductApi,
      documentApi: gatewayDocumentApi,
      agreementApi:
        environment.CONFIRMATION_SERVICE_FEATURE === 'ON'
          ? confirmationServiceApi
          : gatewayAgreementApi,
      agreementGenerationApi: scpAgreementGenerationApi,
      fileUploadApi: gatewayFileUploadApi,
      applicationApi: gatewayApplicationApi,
      addressSuggestionApi: gatewayAddressSuggestionApi,
      offerApi: gatewayOfferApi,
      agentIncentiveApi: gatewayAgentIncentiveApi,
    }),
    []
  );

  return (
    <Provider>
      <NotificationsProvider>
        <Notifications offsetTop={20} reverse />
        <OpeningTimeProvider openingTime={openingTime.current}>
          <PortalConfigProvider
            portalConfig={{
              customerServicePhone: '+639191700700',
            }}
          >
            <TrackingProvider tracking={tracking}>
              <AnalyticsProvider analytics={amplitudeAnalytics}>
                <CustomerAnalyticsProvider
                  analytics={amplitudeCustomerAnalytics}
                >
                  <CalculatorAnalyticsProvider
                    analytics={amplitudeCalculatorAnalytics}
                  >
                    <DiProvider di={di}>
                      <PortalQueryClientProvider>
                        <SessionInitializerWrapper>
                          <PosInitializerWrapper>
                            <AuthGuard>
                              <UserActivityGuard>
                                <ExplainMissedCheckoutGuardHOC>
                                  <Outlet />
                                  <NewsBlockingScreen />
                                </ExplainMissedCheckoutGuardHOC>
                              </UserActivityGuard>
                            </AuthGuard>
                          </PosInitializerWrapper>
                        </SessionInitializerWrapper>
                      </PortalQueryClientProvider>
                    </DiProvider>
                  </CalculatorAnalyticsProvider>
                </CustomerAnalyticsProvider>
              </AnalyticsProvider>
            </TrackingProvider>
          </PortalConfigProvider>
        </OpeningTimeProvider>
      </NotificationsProvider>
    </Provider>
  );
}
