import { useCallback, useEffect, useState } from 'react';

import { setOtpRetryTime } from '~features/auth/otp/lib/otp-request-limits';

import type { AuthOtpResponse, ChallengeParameters } from '~entities/auth';

import { HttpError } from '~shared/errors';
import { useOnMountEffect } from '~shared/hooks';

import isNil from '@tinkoff/utils/is/nil';

import { ButtonLink } from '@breeze-platform-ui/button';
import Text from '@breeze-platform-ui/text';
import { useMachine } from '@xstate/react';

import {
  getOtpRequestDelaySeconds,
  setOtpRequestTime,
  type OTP_TYPE,
  useResendCountdown,
  isAttemptsLimitReached,
  getAttemptsLimitMessage,
  getRequestDelayTime,
} from '../../../lib';
import { resendingStatesMachine } from '../../../model';

interface Props {
  confirmationType: OTP_TYPE;
  phoneNumber: string;
  disabled?: boolean;
  isAuthorizationError: (error: Error) => boolean;
  onResend: () => Promise<void> | Promise<AuthOtpResponse>;
  onAuthError: () => void;
  withAttempts?: boolean;
  confirmationParams?: ChallengeParameters;
  isAttemptsLimitError: (error: Error) => boolean;
  disableRetryAfter?: boolean;
}

export const ResendButton = (props: Props) => {
  const {
    phoneNumber,
    confirmationType,
    disabled,
    isAuthorizationError,
    onResend,
    onAuthError,
    confirmationParams,
    withAttempts,
    isAttemptsLimitError: isAttemptsLimitErrorGuard,
    disableRetryAfter,
  } = props;

  const onCountdownDone = useCallback(
    () => setOtpRetryTime(confirmationType, phoneNumber, 0),
    [phoneNumber, confirmationType]
  );

  const [timer, startCountdown] = useResendCountdown(onCountdownDone);

  const [isAttemptsLimitError, setAttemptsLimitError] = useState(false);

  const [state, send] = useMachine(resendingStatesMachine, {
    services: {
      resend: () => {
        setOtpRequestTime(confirmationType, phoneNumber, new Date().getTime());

        return onResend();
      },
    },
    actions: {
      startCountdown: () =>
        startCountdown(
          getOtpRequestDelaySeconds(
            confirmationType,
            phoneNumber,
            !disableRetryAfter
          )
        ),
      handleAttemptsLimitError: ({ error }: { error: HttpError }) => {
        if (disableRetryAfter) {
          return;
        }

        setOtpRetryTime(
          confirmationType,
          phoneNumber,
          error?.body?.details?.retryAfter ?? 0
        );

        startCountdown(
          getOtpRequestDelaySeconds(
            confirmationType,
            phoneNumber,
            !disableRetryAfter
          )
        );
      },
    },
    guards: {
      isAuthorizationError: ({ error }) =>
        !isNil(error) && isAuthorizationError(error),
      isAttemptsLimitError: ({ error }) =>
        !isNil(error) && isAttemptsLimitErrorGuard(error),
    },
  });

  useOnMountEffect(() => {
    const delay = getOtpRequestDelaySeconds(
      confirmationType,
      phoneNumber,
      !disableRetryAfter
    );

    if (delay) {
      startCountdown(delay);
    }
  });

  useEffect(() => {
    setAttemptsLimitError(
      isAttemptsLimitReached(confirmationType, phoneNumber)
    );
  }, [timer, confirmationType, phoneNumber]);

  const resendDisabled =
    state.matches('loading') || disabled || (!!timer && isAttemptsLimitError);

  useEffect(() => {
    if (state.matches('authorizationError')) {
      onAuthError();
    }
  }, [state, onAuthError]);

  useEffect(() => {
    if (state.matches('countdown') && timer === 0) {
      send({ type: 'COUNTDOWN_OVER' });
    }
  }, [state, timer, send]);

  const showAttemptsLimitTimer = timer > 0 && isAttemptsLimitError;
  const showResendTimer = timer > 0 && !isAttemptsLimitError;
  const remainingAttempts =
    state.context.parameters?.remainingOtpSendAttempts ??
    confirmationParams?.remainingOtpSendAttempts;
  const showLastAttemptWarning =
    remainingAttempts === 0 && withAttempts && !timer;

  return (
    <>
      {showAttemptsLimitTimer && (
        <Text color="rgb(227, 28, 28)">{getAttemptsLimitMessage(timer)}</Text>
      )}
      {showResendTimer && (
        <Text color="rgba(0, 0, 0, 0.4)" size={13}>
          Request a&nbsp;new code in&nbsp;{getRequestDelayTime(timer)}
        </Text>
      )}
      <div>
        {!timer && (
          <ButtonLink
            size="s"
            disableHorizontalPadding
            disableVerticalPadding
            disabled={resendDisabled}
            onClick={() => {
              send({ type: 'RESEND_CODE' });
            }}
          >
            Request a&nbsp;new code via&nbsp;SMS
          </ButtonLink>
        )}
        {showLastAttemptWarning && (
          <div className="text-body-s text-[#e31c1c]">
            It’s your last attempt. If it doesn’t work, your account will
            be&nbsp;blocked
          </div>
        )}
      </div>
      {state.matches('unexpectedError') && (
        <Text color="#e31c1c" size={13}>
          Error receiving the&nbsp;code
        </Text>
      )}
    </>
  );
};
