import { AbortError, HttpError, TimeoutError } from '~shared/errors';

import type { ContextState } from '@tinkoff/request-core';
import {
  isAbortError,
  isHttpError,
  isTimeoutError,
} from '@tinkoff/request-plugin-protocol-http';

import { isErrorResponse, isScpResponse } from './response';

export class ResponseFormatError extends TypeError {
  public constructor(response: unknown) {
    super(
      `Invalid SCP response format: \n\n${JSON.stringify(response, null, 2)}`
    );
  }
}

export type RequestError =
  | HttpError
  | AbortError
  | TimeoutError
  | ResponseFormatError;

export type ErrorFormatter = (state: ContextState) => Error | undefined;

export const errorFormatter: ErrorFormatter = ({ error, response }) => {
  if (isAbortError(error)) {
    return new AbortError(error);
  }
  if (isTimeoutError(error)) {
    return new TimeoutError(error);
  }
  if (isHttpError(error)) {
    if (isScpResponse(response) && isErrorResponse(response)) {
      return new HttpError({ status: error.status, ...response.error });
    }

    if (error.status) {
      return new HttpError({ ...response, ...error });
    }
  }
};
