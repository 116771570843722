import { useEffect, useState } from 'react';

import { Loader } from '~shared/ui/loader';

import { Col } from '@breeze-platform-ui/layout';

import styles from './otp-attempts-error.module.css';

import warningIcon from '../images/alarm.webp';

export const OTPAttemptsError = () => {
  const [imgIsLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = warningIcon;
    img.onload = () => setIsLoaded(true);
  }, []);

  if (!imgIsLoaded) {
    return <Loader />;
  }

  return (
    <Col gaps={16} alignCross="center" padding="64px 32px">
      <img src={warningIcon} width={190} height={190} />
      <div className={styles.title}>
        You’ve reached the&nbsp;limit of&nbsp;attempts to&nbsp;enter
        the&nbsp;OTP
      </div>
    </Col>
  );
};
