import { useNavigate } from 'react-router-dom';

import { UpdateAttendanceStatus } from '~features/update-attendance-status';

import { useAgentInfo } from '~entities/agent';
import { isIncentiveProgramAvailableAtom } from '~entities/agent-incentive';
import { useAttendanceStatus } from '~entities/attendance';
import { useGetDailySchedule, WorkDayDetails } from '~entities/shedule';

import { ActionMenu, Action } from '~shared/ui/action-menu';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Int24Call } from '@breeze-platform-ui/iconsPack';
import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';

import styles from './agent-account.module.css';

import rewardSrc from '../../images/reward.svg';
import skyroLeadsSrc from '../../images/skyro-leads.svg';
import socialLeadsSrc from '../../images/social-leads.svg';

type Props = {
  customerServicePhone: string;
};
export const AgentAccount = ({ customerServicePhone }: Props) => {
  const navigate = useNavigate();
  const agentTypeQuery = useAgentInfo({
    select: (info) => info.employmentType,
  });

  const attendanceStatus = useAttendanceStatus({
    enabled: agentTypeQuery.data === 'FULL_TIME',
  });
  const dailyScheduleQuery = useGetDailySchedule(
    format(new Date(), 'yyyy-MM-dd'),
    { enabled: agentTypeQuery.data === 'FULL_TIME' }
  );

  const isIncentiveProgramAvailable = useAtomValue(
    isIncentiveProgramAvailableAtom
  );

  const isDataLoading =
    attendanceStatus.isLoading ||
    agentTypeQuery.isLoading ||
    dailyScheduleQuery.isLoading;
  const isFullTime = agentTypeQuery.data === 'FULL_TIME';
  const isWorkingDay = dailyScheduleQuery.data?.type === 'WORK';

  const shouldLogAttendance = !isDataLoading && isFullTime && isWorkingDay;

  return (
    <>
      <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
        <Col gaps={20} alignCross="stretch">
          {isFullTime ? (
            <div className={styles.container} data-qa-type="work-day-details">
              <WorkDayDetails
                isLoading={dailyScheduleQuery.isLoading}
                schedule={dailyScheduleQuery.data ?? null}
              />

              {shouldLogAttendance && <UpdateAttendanceStatus />}
            </div>
          ) : (
            <ScreenTitle title="My account" margin="0 0 16px 8px" />
          )}
          {isIncentiveProgramAvailable && (
            <ActionMenu>
              <Action
                icon={
                  <img src={rewardSrc} alt="reward" width={40} height={40} />
                }
                href="/agent-incentive"
                onClick={() => navigate('/agent-incentive')}
              >
                <Col>
                  <Text size={15} bold>
                    Bonuses
                  </Text>
                </Col>
              </Action>
            </ActionMenu>
          )}
          <ActionMenu>
            {isFullTime && (
              <>
                <Action
                  icon={
                    <img
                      src={socialLeadsSrc}
                      alt="reward"
                      width={40}
                      height={40}
                    />
                  }
                  href="social-leads"
                  onClick={() => navigate('/social-leads')}
                >
                  <Text size={15} bold>
                    My leads
                  </Text>
                </Action>
                <Action
                  icon={
                    <img
                      src={skyroLeadsSrc}
                      alt="reward"
                      width={40}
                      height={40}
                    />
                  }
                  href="skyro-leads"
                  onClick={() => navigate('/skyro-leads')}
                >
                  <Text size={15} bold>
                    Skyro leads
                  </Text>
                </Action>
              </>
            )}
            <Action
              icon={
                <Int24Call
                  theme={{
                    color: '#4D77FF',
                    background: 'rgba(236, 241, 247, 1)',
                  }}
                  withContainer
                  containerSize={40}
                />
              }
              href={`tel:${customerServicePhone}`}
            >
              <Col>
                <Text size={15} bold>
                  Call customer care
                </Text>
                <Text size={13} color="rgba(0, 0, 0, 0.54)">
                  Available from 9 AM to 6 PM
                </Text>
              </Col>
            </Action>
          </ActionMenu>
        </Col>
      </Screen>
    </>
  );
};
