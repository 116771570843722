import { useCallback, useMemo, useRef, useState } from 'react';

import {
  useAnalytics,
  useCustomerAnalytics,
} from '~app/providers/analytics-provider';

import { ClientAssessment } from '~widgets/client-assessment';
import { ClientPhoto } from '~widgets/client-photo';

import { usePhonesUnicityValidation } from '~features/phones-unicity';

import { Application } from '~entities/application';
import { Person, PersonPatchUpdatePayload } from '~entities/person';

import { mergeFio } from '~shared/lib/merge-fio';
import { StepFlowStrategy } from '~shared/lib/step-flow-strategy';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';
import { Step, Stepper } from '~shared/ui/stepper';

import { EmploymentDetails } from '../../employment-details';
import { Finances } from '../../finances';
import {
  ApplicationStepName,
  applicationStepNames,
  featureFlags,
  FormErrors,
  FormValues,
  PersonDetailsStepName,
  personDetailsStepNames,
  StepName,
  stepNames,
  recoverPersonScreenValues,
  formatApplicationScreenValues,
  formatPersonScreenValues,
} from '../../lib';
import { FormState, StepFlowMachine } from '../../model';
import { PersonalReference } from '../../personal-reference';
import { Profile } from '../../profile';
import { WorkReference } from '../../work-reference';

export type XsellShortApplicationProps = {
  clientPhone: string;
  applicationId: string;
  person: Partial<Person>;
  isProfileFilled?: boolean;
  isSubmitInProgress?: boolean;
  onUpdatePerson: (person: PersonPatchUpdatePayload) => void;
  onUpdateApplication: (application: Application) => void;
  onSubmit: (application: Application) => void;
  onClose: () => void;
};

export const XsellShortApplicationForm = ({
  clientPhone,
  applicationId,
  person,
  isProfileFilled,
  isSubmitInProgress,
  onUpdatePerson,
  onUpdateApplication,
  onSubmit,
  onClose,
}: XsellShortApplicationProps) => {
  const initialStep = isProfileFilled ? stepNames.finances : stepNames.profile;
  const stepFlowStrategy = useMemo(
    () => new StepFlowStrategy<StepName>(StepFlowMachine(initialStep)),
    [initialStep]
  );
  const [currentStep, setCurrentStep] = useState<StepName>(initialStep);
  const [formState, setFormState] = useState<FormState>(() => ({
    values: recoverPersonScreenValues(person) ?? {},
    errors: {},
  }));

  const analytics = useAnalytics();
  const customerAnalytics = useCustomerAnalytics();

  const backWasUsedRef = useRef(false);

  const { validatePhone, handlePhoneUpdate } = usePhonesUnicityValidation({
    personal: clientPhone,
    work: formState.values.workReference?.workPhone,
    alternative: formState.values.personalReference?.number,
    additional: undefined,
  });

  const goBack = () => {
    const prevScreenName = stepFlowStrategy.prev();
    setCurrentStep(prevScreenName);
    backWasUsedRef.current = true;
  };

  const goNext = (currentStepValues: FormValues[StepName]) => {
    const nextStep = stepFlowStrategy.next({
      ...formState.values,
      [currentStep]: currentStepValues,
    });
    setCurrentStep(nextStep);
  };

  const buildApplication = useCallback(
    (
      currentStepValues: FormValues[ApplicationStepName],
      currentStepErrors: FormErrors[ApplicationStepName]
    ) => {
      const formValues = {
        ...formState.values,
        [currentStep]: currentStepValues,
      };
      const formErrors = {
        ...formState.errors,
        [currentStep]: currentStepErrors,
      };
      const futureSteps = stepFlowStrategy
        .getFullFlow(formValues)
        .filter(
          (step) => step in applicationStepNames
        ) as ApplicationStepName[];

      return formatApplicationScreenValues(futureSteps, formValues, formErrors);
    },
    [formState, stepFlowStrategy, currentStep]
  );

  const buildPerson = (
    currentStepValues: Partial<FormValues[PersonDetailsStepName]>,
    currentStepErrors: Partial<FormErrors[PersonDetailsStepName]>
  ) => {
    return formatPersonScreenValues(
      currentStep,
      currentStepValues,
      currentStepErrors
    );
  };

  const handleFormStateUpdate = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    setFormState({
      values: { ...formState.values, [currentStep]: currentStepValues },
      errors: { ...formState.errors, [currentStep]: currentStepErrors },
    });
  };

  const handleFormChange = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: Partial<FormErrors[StepName]>
  ) => {
    if (isSubmitInProgress) {
      return;
    }

    const isPersonDetailsStep = currentStep in personDetailsStepNames;

    if (isPersonDetailsStep) {
      onUpdatePerson(
        buildPerson(
          currentStepValues as Partial<FormValues[PersonDetailsStepName]>,
          currentStepErrors as Partial<FormErrors[PersonDetailsStepName]>
        )
      );
    }

    if (!isPersonDetailsStep) {
      onUpdateApplication(
        buildApplication(
          currentStepValues as Partial<FormValues[ApplicationStepName]>,
          currentStepErrors as Partial<FormErrors[ApplicationStepName]>
        )
      );
    }
  };

  const handleStepSubmit = (currentStepValues: FormValues[StepName]) => {
    handleFormStateUpdate(currentStepValues, {});
    handleFormChange(currentStepValues, {});
    goNext(currentStepValues);
  };

  const handleGoBack = (
    currentStepValues: Partial<FormValues[StepName]>,
    currentStepErrors: FormErrors[StepName]
  ) => {
    handleFormStateUpdate(currentStepValues, currentStepErrors);
    goBack();
  };

  const handleFinalSubmit = (
    currentStepValues: FormValues[StepName],
    skipServerValidation?: boolean
  ) => {
    handleFormStateUpdate(currentStepValues, {});

    if (backWasUsedRef.current) {
      analytics.trackFormBackClick();
      customerAnalytics.trackFormBackClick({ applicationId });
    }

    const application = buildApplication(
      currentStepValues as Partial<FormValues[ApplicationStepName]>,
      {}
    );
    const finishedApplication: Application = {
      ...application,
      formFillingStatus: 'FINISHED',
    };

    onSubmit(
      skipServerValidation
        ? { ...finishedApplication, ...featureFlags }
        : finishedApplication
    );
  };

  const handleClose = () => {
    if (backWasUsedRef.current) {
      analytics.trackFormBackClick();
      customerAnalytics.trackFormBackClick({ applicationId });
    }

    onClose();
  };

  const clientName = mergeFio(
    person?.name?.firstName,
    person?.name?.lastName,
    person?.name?.middleName
  );

  return (
    <Stepper current={currentStep}>
      <Step name={stepNames.profile}>
        <ScrollIntoView>
          <Profile
            name={clientName}
            initialValue={formState.values[stepNames.profile]}
            onFieldCompleted={handleFormChange}
            onSubmit={handleStepSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.finances}>
        <ScrollIntoView>
          <Finances
            name={clientName}
            initialValue={formState.values[stepNames.finances]}
            onPrev={isProfileFilled ? undefined : handleGoBack}
            onFieldCompleted={handleFormChange}
            onSubmit={handleStepSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.employmentDetails}>
        <ScrollIntoView>
          <EmploymentDetails
            name={clientName}
            initialValue={formState.values[stepNames.employmentDetails]}
            onPrev={handleGoBack}
            onFieldCompleted={handleFormChange}
            onSubmit={handleStepSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.workReference}>
        <ScrollIntoView>
          <WorkReference
            name={clientName}
            initialValue={formState.values[stepNames.workReference]}
            employmentStatus={nonNullableValue(
              formState.values.employmentDetails?.employmentStatus
            )}
            validatePhoneUnicity={validatePhone}
            onPhoneUpdate={handlePhoneUpdate}
            onPrev={handleGoBack}
            onFieldCompleted={handleFormChange}
            onSubmit={handleStepSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.personalReference}>
        <ScrollIntoView>
          <PersonalReference
            name={clientName}
            initialValue={formState.values[stepNames.personalReference]}
            validatePhoneUnicity={validatePhone}
            onPhoneUpdate={handlePhoneUpdate}
            onPrev={handleGoBack}
            onFieldCompleted={handleFormChange}
            onSubmit={handleStepSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.clientPhoto}>
        <ScrollIntoView>
          <ClientPhoto
            name={clientName}
            applicationId={applicationId}
            onPrev={goBack}
            onSubmit={goNext}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
      <Step name={stepNames.clientAssessment}>
        <ScrollIntoView>
          <ClientAssessment
            name={clientName}
            initialValue={formState.values[stepNames.clientAssessment]}
            onPrev={handleGoBack}
            onSubmit={handleFinalSubmit}
            onClose={handleClose}
          />
        </ScrollIntoView>
      </Step>
    </Stepper>
  );
};
