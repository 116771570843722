import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { InputMoney } from '@breeze/rhf-adapters';

import { MONTHLY_INCOME_REQUIRED_ERROR } from '../constants/field-errors';
import { FinancesFormValues } from '../model/form-values';

type Props = Readonly<{
  name: string;
  initialValue?: Partial<FinancesFormValues>;
  onPrev?: (
    values: Partial<FinancesFormValues>,
    errors: FieldErrors<FinancesFormValues>
  ) => void;
  onSubmit: (values: FinancesFormValues) => void;
  onFieldCompleted?: (
    value: Partial<FinancesFormValues>,
    errors: FieldErrors<FinancesFormValues>
  ) => void;
  onClose: () => void;
}>;

export const Finances = ({
  name,
  initialValue,
  onFieldCompleted,
  onSubmit,
  onClose,
  onPrev,
}: Props) => {
  const methods = useForm<FinancesFormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { control, handleSubmit, getValues } = methods;

  useRevalidate(methods);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FinancesFormValues>(methods);

    onPrev?.(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FinancesFormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev && handleClickButtonPrev} />}
      >
        <div>
          <ScreenTitle
            title="Average monthly income"
            subtitle="Salary, remittances, renting out housing, etc."
          />
          <Controller
            control={control}
            name="personMonthlyIncome"
            rules={{
              required: MONTHLY_INCOME_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <InputMoney
                {...fieldProps}
                label="Monthly income"
                currency="PHP"
                currencyInValue
                symbolPosition="prefix"
                digitGroupingSeparator=","
                maxLength={11} // Includes digitGroupingSeparator
                tabIndex={1}
                autoFocus
              />
            )}
          />
        </div>
      </Screen>
    </form>
  );
};
