import Clickable from '@breeze-platform-ui/clickable';
import { Int16Close } from '@breeze-platform-ui/iconsPack';

interface Props {
  onClick: (e: React.MouseEvent<unknown, MouseEvent>) => void;
}

export const PopupCloseButton = ({ onClick }: Props) => {
  return (
    <Clickable
      className="rounded-full border-none bg-[#f6f7f8]"
      innerClassName="flex min-h-8 min-w-8 cursor-pointer items-center justify-center"
      onClick={onClick}
    >
      <Int16Close theme={{ color: '#9299A2' }} />
    </Clickable>
  );
};
