import type {
  PersonPatchFormatter,
  PersonPatchScreensMapping,
} from '~entities/person/lib';
import { patchFormatValuesByMapping } from '~entities/person/lib';

import { PersonalReferenceFormValues } from '../model/form-values';

const personalReferenceMapping: PersonPatchScreensMapping<PersonalReferenceFormValues> =
  {
    type: { path: ['additionalContact', 'type'] },
    name: { path: ['additionalContact', 'name'] },
    number: { path: ['additionalContact', 'number'] },
    familyRelation: { path: ['additionalContact', 'familyRelation'] },
    familyRelationOther: { path: ['additionalContact', 'familyRelationOther'] },
  };

export const patchFormatPersonalReference: PersonPatchFormatter<
  PersonalReferenceFormValues
> = (person, screenValues) => {
  return patchFormatValuesByMapping(
    person,
    screenValues,
    personalReferenceMapping
  );
};
