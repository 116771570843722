import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { BarcodeDetection } from '~widgets/barcode-detection';

import type { ManufacturerIdType } from '~entities/product';

import { Col } from '@breeze-platform-ui/layout';

import { IdPhotoUpload } from './id-photo-upload';
import { ManufacturerIdNumber } from './manufacturer-id-number';

import type { LoanDetailsFormValues } from '../../types';

type Props = {
  type?: ManufacturerIdType;
  applicationId: string;
  index: number;
  isZeroRate: boolean;
  isRequired?: boolean;
};

export const UploadManufacturerId = ({
  index,
  type,
  applicationId,
  isZeroRate,
  isRequired,
}: Props) => {
  const customerAnalytics = useCustomerAnalytics();
  const { formState, watch, setValue, getValues } =
    useFormContext<LoanDetailsFormValues>();
  const [detectedFile, setDetectedFile] = useState<File>();
  const [detectedCode, setDetectedCode] = useState<string>();
  const [barcodeDetectorOpened, setBarcodeDetectorOpened] = useState(false);

  const manufacturerIdValid =
    watch(`itemsDetails.${index}.itemManufacturerId`) &&
    !formState.errors.itemsDetails?.[index]?.itemManufacturerId;

  useEffect(() => {
    if (detectedCode) {
      setValue(`itemsDetails.${index}.itemManufacturerId`, detectedCode, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [detectedCode, index, setValue]);

  const handleIdInputBlur = () => {
    if (
      detectedCode &&
      getValues().itemsDetails[index].itemManufacturerId !== detectedCode
    ) {
      customerAnalytics.trackBarcodeRecognitionEvent('edit_manually', {
        applicationId,
      });
    }
  };

  const handleBarcodeDetectorClick = () => {
    customerAnalytics.trackBarcodeRecognitionEvent('scan', { applicationId });
    setBarcodeDetectorOpened(true);
  };

  const handleBarcodeDetect = ({
    value,
    file,
  }: {
    value: string;
    file: File;
  }) => {
    setDetectedCode(value);
    if (isZeroRate) {
      setDetectedFile(file);
    }
  };
  return (
    <>
      <Col alignCross="stretch" gaps={16}>
        <ManufacturerIdNumber
          index={index}
          type={type}
          onBarcodeDetectorClick={handleBarcodeDetectorClick}
          onBlur={handleIdInputBlur}
          isRequired={isRequired}
        />
        {manufacturerIdValid && isZeroRate && (
          <IdPhotoUpload
            index={index}
            type={type as ManufacturerIdType}
            applicationId={applicationId}
            detectedFile={detectedFile}
          />
        )}
      </Col>
      {barcodeDetectorOpened && (
        <BarcodeDetection
          applicationId={applicationId}
          onClose={() => setBarcodeDetectorOpened(false)}
          onDone={handleBarcodeDetect}
        />
      )}
    </>
  );
};
