import { type AgentNewsDetails, isItemImportant } from '~entities/agent-news';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';

import { Article } from './article';

type Props = {
  item: AgentNewsDetails;
  onMarkRead: (id: string) => void;
};

const shouldShowReadButton = (item: AgentNewsDetails) =>
  isItemImportant(item) && !item.isRead;

export const GeneralNewsItem = ({ item, onMarkRead }: Props) => {
  return (
    <Col alignCross="stretch">
      <Article item={item} />
      {shouldShowReadButton(item) && (
        <Button
          wide
          theme="primary"
          onClick={() => onMarkRead(item.documentId)}
        >
          I’ve read it!
        </Button>
      )}
      {item.isRead && isItemImportant(item) && (
        <NotificationInline
          type="success"
          animateFirstRender={false}
          timer={false}
          showClose={false}
          withIcon
          title="You’ve read this news"
        />
      )}
    </Col>
  );
};
