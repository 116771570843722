import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { environment } from '~app/environment';

import {
  getAttemptsLimitMessage,
  getOtpRequestDelaySeconds,
  getRequestDelayTime,
  isAttemptsLimitReached,
  useResendCountdown,
} from '~features/auth/otp/lib';
import { usePhonesUnicityValidation } from '~features/phones-unicity';

import { agentPhoneAtom } from '~entities/auth';

import { combineValidators } from '~shared/lib/combine-validators';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { LoadingButton, ButtonLink } from '@breeze-platform-ui/button';
import { Box, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { InputPhone, MaskedInput } from '@breeze/rhf-adapters';
import { useAtomValue } from 'jotai';

export type FormValues = { phone: string; promoterCode?: string };

type Props = {
  presetPhone?: string | null;
  onSubmit: (phoneNumber: string, promoterCode?: string) => void;
  showPromoterCode?: boolean;
  isLoading: boolean;
};

export const ClientPhone = ({
  presetPhone,
  onSubmit,
  showPromoterCode,
  isLoading,
}: Props) => {
  const promoterCodeFeatureEnabled = environment.PROMOTER_CODE_FEATURE === 'ON';
  const agentPhone = useAtomValue(agentPhoneAtom);
  const [timer, startCountdown, cancelCountdown] = useResendCountdown();
  const [currentPhone, setCurrentPhone] = useState(presetPhone);
  const [isAttemptsLimitError, setAttemptsLimitError] = useState(false);

  const { control, handleSubmit, setError, clearErrors } = useForm<FormValues>({
    mode: 'all',
    defaultValues: presetPhone ? { phone: presetPhone } : undefined,
  });
  const [showPromoterCodeInput, setShowPromoterCodeInput] = useState(false);

  const { validatePhone, handlePhoneUpdate } = usePhonesUnicityValidation({
    agent: agentPhone ?? undefined,
  });

  const handleFormSubmit = ({ phone, promoterCode }: FormValues) => {
    setCurrentPhone(phone);

    const delay = getOtpRequestDelaySeconds('login', phone);

    if (delay) {
      startCountdown(delay);
    } else {
      onSubmit(phone, promoterCode);
    }
  };

  useEffect(() => {
    if (
      currentPhone &&
      timer &&
      isAttemptsLimitReached('login', currentPhone)
    ) {
      setAttemptsLimitError(true);
      setError('phone', { message: getAttemptsLimitMessage(timer) });
    } else {
      setAttemptsLimitError(false);
      clearErrors('phone');
    }
  }, [timer, currentPhone, clearErrors, setError]);

  const showResendTimer = !!timer && !isAttemptsLimitError && !isLoading;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <ScreenTitle title="Enter customer’s mobile number to&nbsp;start a&nbsp;new application" />
      <Controller
        name="phone"
        control={control}
        rules={{
          required: 'Enter customer’s mobile number',
          validate: combineValidators(
            validators.phoneLength(),
            validators.mobilePhoneFormat(),
            validatePhone('personal')
          ),
        }}
        render={({ field, ...props }) => (
          <InputPhone
            {...props}
            label="Mobile number"
            field={{
              ...field,
              onChange: (...args) => {
                if (timer) {
                  cancelCountdown();
                }
                field.onChange(...args);

                const { error } = props.fieldState;

                handlePhoneUpdate(!error ? field.value : undefined, 'personal');
              },
            }}
            disabled={!!presetPhone}
          />
        )}
      />
      {promoterCodeFeatureEnabled &&
        showPromoterCode &&
        !showPromoterCodeInput && (
          <Row margin="0 0 20px">
            <ButtonLink
              disableHorizontalPadding
              disableVerticalPadding
              size="m"
              onClick={() => setShowPromoterCodeInput(true)}
            >
              Customer has a brand promoter’s code
            </ButtonLink>
          </Row>
        )}
      {showPromoterCodeInput && (
        <Controller
          name="promoterCode"
          control={control}
          rules={{
            pattern: {
              value: /^\d{6}$/,
              message: 'Enter the full promoter’s code',
            },
          }}
          render={(fieldProps) => (
            <MaskedInput
              partialPlaceholder
              label="Brand promoter’s code"
              mask="000000"
              maskTransitions={{ 0: /\d/i }}
              placeholder="000000"
              {...fieldProps}
            />
          )}
        />
      )}
      <div>
        {showResendTimer && (
          <Box margin="0 0 16px 0">
            <Text color="rgba(0, 0, 0, 0.4)" size={13}>
              Request a&nbsp;new code in&nbsp;{getRequestDelayTime(timer)}
            </Text>
          </Box>
        )}
        <LoadingButton
          loading={isLoading}
          type="submit"
          size="l"
          wide
          disabled={!!timer}
        >
          Send a code
        </LoadingButton>
      </div>
    </form>
  );
};
