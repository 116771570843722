import type { UserMetadata } from '~entities/application';

import type { UserInfo } from '../auth';

export type Metadata = {
  userMetadata: WebFingerprint;
};
export type WebFingerprint = { webFingerprint: UserMetadata };

export type InitiateParams = Metadata & {
  authParameters: { phoneNumber: string };
};

export type UpgradeInitiateParams = Metadata & {
  authParameters:
    | {
        phoneNumber: string;
        posId: string;
        merchantId: string;
        productType: 'POS_LOAN';
      }
    | { applicationId: string };
};

export interface AuthOtpResponse {
  challengeKind: string;
  challengeParameters: {
    username: string;
    delaySec: string;
    expiresAt: string;
    isNew: boolean;
    remainingOtpEnterAttempts: number;
    remainingOtpSendAttempts: number;
  };
}

export type AuthSuccessResponse = object;

export function isOtpResponse(
  response: AuthOtpResponse | AuthSuccessResponse
): response is AuthOtpResponse {
  return typeof (response as AuthOtpResponse)?.challengeKind === 'string';
}

export function isSuccessResponse(
  response: unknown
): response is AuthOtpResponse {
  return !isOtpResponse(response as AuthOtpResponse | AuthSuccessResponse);
}

export type RespondParams = Metadata & {
  answer: string;
};

export interface ValidateResponse {
  auth: string; // Format: '2022-09-16T11:35:18.596730916Z';
  refresh: string; // Format: '2022-09-16T11:41:18.596730916Z';
  exp: string; // Format '2022-09-16T11:47:18.596730916Z';
  refreshRemainingMinutes: number;
  expRemainingMinutes: number;
}

export interface AuthApi {
  initiate: (params: InitiateParams) => Promise<AuthOtpResponse>;
  respond: (params: RespondParams) => Promise<AuthSuccessResponse>;
  resendConfirmation: () => Promise<AuthOtpResponse>;
  validate: (params: WebFingerprint) => Promise<ValidateResponse>;
  upgradeInitiate: (
    params: UpgradeInitiateParams
  ) => Promise<AuthOtpResponse | AuthSuccessResponse>;
  upgradeRespond: (params: RespondParams) => Promise<AuthSuccessResponse>;
  upgradeResendConfirmation: () => Promise<AuthOtpResponse>;
  confirmOtpSending: () => Promise<AuthSuccessResponse>;
  revoke: () => Promise<void>;
  getUserInfo: () => Promise<UserInfo>;
}
