import { useParams } from 'react-router-dom';

import type {
  ApplicationDetails,
  ApplicationProcessStatus,
} from '~entities/application';

import { LoadingButton } from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';

import { useContinueApplication } from '../../lib/use-continue-application';
import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
  applicationDetails: ApplicationDetails;
};
export const Onboarding = ({ processStatus, applicationDetails }: Props) => {
  const { id = '' } = useParams();
  const { isPending, continueApplication } = useContinueApplication(
    id,
    applicationDetails
  );

  return (
    <Island
      theme="shadow"
      text="Continue filling out&nbsp;the&nbsp;application or cancel it if not needed anymore"
      title={<ProcessStatusTitle processStatus={processStatus} />}
      footerBody={
        <LoadingButton
          wide
          size="m"
          onClick={continueApplication}
          loading={isPending}
        >
          Continue application
        </LoadingButton>
      }
    />
  );
};
