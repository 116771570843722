import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '~app/environment';
import { useCustomerAnalytics } from '~app/providers/analytics-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { ClientInitiationNavigationState } from '~pages/client-initiation';

import { FormLoadingPage } from '~widgets/form-loading-page';
import { XsellShortApplicationForm } from '~widgets/xsell-short-application';

import { ActiveApplicationWarning } from '~features/create-application-result';

import {
  Application,
  applicationsKeys,
  useApplicationInitialMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
} from '~entities/application';
import { getDeviceMetadata } from '~entities/device-metadata';
import {
  useGetPersonQuery,
  useCheckProfileQuery,
  usePatchUpdatePersonMutation,
} from '~entities/person';
import { selectedPosAtom } from '~entities/pos';

import {
  useFormRenderTime,
  useApplicationPageNavigationConstraints,
} from '~shared/hooks';

import Text from '@breeze-platform-ui/text';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { buildApplicationLoanDetails } from '../lib/build-application-loan-details';

export const XsellShortApplicationPage = () => {
  const navigate = useNavigate();
  const getApplicationQuery = useGetApplicationQuery();
  const checkProfileQuery = useCheckProfileQuery();
  const getPersonQuery = useGetPersonQuery();
  const isFormLoading =
    checkProfileQuery.isLoading ||
    getApplicationQuery.isLoading ||
    getPersonQuery.isLoading;
  const { loanCalculator, promoterCode } =
    (useLocation().state as ClientInitiationNavigationState) || {};

  const isFirstApplicationUpdateRef = useRef(true);

  useFormRenderTime(isFormLoading);

  const posId = useAtomValue(selectedPosAtom)?.id;
  const tracking = useTracking();
  const analytics = useCustomerAnalytics();

  const {
    mutate: updateApplication,
    finalMutateAsync: submitApplicationAsync,
  } = useUpdateApplicationMutation();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const { mutate: initialUpdateApplication } = useApplicationInitialMutation();
  const { mutate: updatePerson } = usePatchUpdatePersonMutation();
  const queryClient = useQueryClient();

  const handleClose = () => {
    navigate('/');
  };

  useApplicationPageNavigationConstraints();

  if (
    getApplicationQuery.data &&
    getPersonQuery.data &&
    checkProfileQuery.data
  ) {
    const loanDetails = buildApplicationLoanDetails(
      getApplicationQuery.data,
      loanCalculator
    );

    const buildFullApplication = (application: Application) => ({
      ...application,
      ...loanDetails,
      posId,
      setSelfAppFlowToAgentPortal: true,
      metaData: {
        promoterCode,
        appVersion: environment.VERSION,
        ...getDeviceMetadata(tracking?.state),
      },
    });

    const handleApplicationUpdate = (newApplication: Application) => {
      const application = buildFullApplication(newApplication);
      updateApplication(application);
    };

    const isApplicationSubmitted =
      getApplicationQuery.data.applicationStatus !== 'NEW' ||
      getApplicationQuery.data.formFillingStatus === 'FINISHED';

    if (isApplicationSubmitted) {
      return (
        <ActiveApplicationWarning applicationId={getApplicationQuery.data.id} />
      );
    }

    if (isFirstApplicationUpdateRef.current) {
      isFirstApplicationUpdateRef.current = false;

      const application = buildFullApplication({
        formFillingStatus: 'IN_PROGRESS',
      });
      initialUpdateApplication(application);
      analytics.trackXsellShortFormFillingStarted({
        applicationId: getApplicationQuery.data.id,
      });
    }

    const handleSubmit = async (application: Application) => {
      /** TODO: add submit event tracking */
      if (isSubmitInProgress) {
        return;
      }
      setIsSubmitInProgress(true);

      try {
        await submitApplicationAsync(buildFullApplication(application));
        queryClient.resetQueries({
          queryKey: applicationsKeys.authorizedApplication(),
        });
        queryClient.resetQueries({
          queryKey: applicationsKeys.application(getApplicationQuery.data.id),
        });
        queryClient.resetQueries({
          queryKey: applicationsKeys.listByPos(posId),
        });

        analytics.trackXsellShortFormSubmitted({
          applicationId: getApplicationQuery.data.id,
        });
        navigate(`/applications/${getApplicationQuery.data.id}`, {
          replace: true,
        });
      } finally {
        setIsSubmitInProgress(false);
      }
    };

    /** TODO: track form filling started event */
    return (
      <XsellShortApplicationForm
        isProfileFilled={checkProfileQuery.data.profileFilled}
        clientPhone={getApplicationQuery.data.person.mobilePhone}
        applicationId={getApplicationQuery.data.id}
        person={getPersonQuery.data}
        isSubmitInProgress={isSubmitInProgress}
        onSubmit={handleSubmit}
        onUpdatePerson={updatePerson}
        onUpdateApplication={handleApplicationUpdate}
        onClose={handleClose}
      />
    );
  }

  if (isFormLoading) {
    return <FormLoadingPage />;
  }

  return <Text>Application creation error</Text>;
};
