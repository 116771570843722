import { environment } from '~app/environment';

import { useQueuedMutation } from '~shared/react-query-helpers';
import { makeRequest } from '~shared/scp-client';

import { ResponseSuccess } from '@breezeventures/scp-client';

import { SurveyDataPayload } from './api-types';

export const useUpdateSurveyData = () => {
  return useQueuedMutation({
    mutationFn: (payload: SurveyDataPayload) => {
      return makeRequest<ResponseSuccess<SurveyDataPayload>>({
        url: `${environment.API_HOST}/agent-portal/api/v1/public/survey/result/update`,
        payload,
        credentials: 'include',
      }).then((response) => response.payload);
    },
    retry: 4,
  });
};
