import { CANT_INSTALL_APP_QUESTION } from '~widgets/client-assessment/lib/client-phone-question';

import type {
  Application,
  ApplicationFormatter,
  ApplicationRecovery,
} from '~entities/application';

import type { FormValues } from '../ui/client-phone-assessment';

const trueFalseMapping = {
  true: 'false',
  false: 'true',
} as const;

export const formatClientPhoneAssessment: ApplicationFormatter<FormValues> = (
  application,
  stepValues
) => {
  const { canInstallApp } = stepValues;
  const { employeeQuestionnaire } = application;
  const questionnaireWithoutAppQuestion =
    employeeQuestionnaire?.filter(
      (q) => q.question !== CANT_INSTALL_APP_QUESTION
    ) ?? [];

  return {
    ...application,
    employeeQuestionnaire: [
      ...questionnaireWithoutAppQuestion,
      {
        question: CANT_INSTALL_APP_QUESTION,
        answer: (canInstallApp && trueFalseMapping[canInstallApp]) || 'false',
      },
    ],
  };
};

export const recoverClientPhoneAssessment: ApplicationRecovery<FormValues> = (
  application: Application
) => {
  const employeeQuestionnaire = application.employeeQuestionnaire || [];
  const cantInstallApp = employeeQuestionnaire.find(
    (q) => q.question === CANT_INSTALL_APP_QUESTION
  );

  const cantInstallAppAnswer = cantInstallApp?.answer;

  return {
    canInstallApp:
      (cantInstallAppAnswer && trueFalseMapping[cantInstallAppAnswer]) ||
      'true',
  };
};
