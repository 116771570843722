import { useCallback, useEffect } from 'react';
import { Controller, FieldErrors, useForm, Validate } from 'react-hook-form';

import { PhoneType } from '~entities/person';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { NotificationInline } from '@breeze-platform-ui/notification';
import { InputCapitalize, InputPhone, Select } from '@breeze/rhf-adapters';

import {
  REFERENCE_NAME_REQUIRED_ERROR,
  REFERENCE_PHONE_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../constants/field-errors';
import {
  FAMILY_RELATION_FIELD_NAME,
  REFERENCE_NAME_FIELD_NAME,
  REFERENCE_PHONE_FIELD_NAME,
  REFERENCE_TYPE_FIELD_NAME,
} from '../constants/field-names';
import { familyRelationOptions } from '../lib/family-relation-options';
import { personalReferenceTypeOptions } from '../lib/personal-reference-type-options';
import { PersonalReferenceFormValues } from '../model/form-values';

type Props = Readonly<{
  name: string;
  initialValue?: Partial<PersonalReferenceFormValues>;
  onPrev?: (
    values: Partial<PersonalReferenceFormValues>,
    errors: FieldErrors<PersonalReferenceFormValues>
  ) => void;
  onSubmit: (values: PersonalReferenceFormValues) => void;
  onFieldCompleted?: (
    value: Partial<PersonalReferenceFormValues>,
    errors: FieldErrors<PersonalReferenceFormValues>
  ) => void;
  validatePhoneUnicity: (
    phoneType: PhoneType
  ) => Validate<string | undefined, PersonalReferenceFormValues>;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
  onClose: () => void;
}>;

export const PersonalReference = ({
  name,
  initialValue,
  onFieldCompleted,
  validatePhoneUnicity,
  onPhoneUpdate,
  onSubmit,
  onClose,
  onPrev,
}: Props) => {
  const methods = useForm<PersonalReferenceFormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues, getFieldState, trigger, watch } =
    methods;

  useRevalidate(methods);

  const triggerPhone = useCallback(async () => {
    const value = getValues('number');

    if (getFieldState('number').isDirty || value) {
      const isValid = await trigger('number');

      onPhoneUpdate(isValid ? value : undefined, 'alternative');
    }
  }, [getFieldState, getValues, trigger, onPhoneUpdate]);

  useEffect(() => {
    triggerPhone();
  }, [triggerPhone, validatePhoneUnicity]);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<PersonalReferenceFormValues>(methods);

    onPrev?.(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<PersonalReferenceFormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const isFamilyMember = watch('type') === 'FAMILY_MEMBER';

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev && handleClickButtonPrev} />}
      >
        <div>
          <ScreenTitle
            title="Personal reference"
            subtitle="Must&nbsp;be someone who knows the&nbsp;customer well"
          />

          <NotificationInline
            type="info"
            timer={false}
            showClose={false}
            paddingBottom={20}
          >
            Ask customer to&nbsp;inform this contact that they might get
            a&nbsp;call from&nbsp;us
          </NotificationInline>

          <Controller
            control={control}
            name="type"
            rules={{
              required: SELECT_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <Select
                label={REFERENCE_TYPE_FIELD_NAME}
                options={personalReferenceTypeOptions}
                {...fieldProps}
              />
            )}
          />
          {isFamilyMember && (
            <Controller
              name="familyRelation"
              control={control}
              shouldUnregister
              rules={{
                required: SELECT_REQUIRED_ERROR,
              }}
              render={(fieldProps) => (
                <Select
                  label={FAMILY_RELATION_FIELD_NAME}
                  options={familyRelationOptions}
                  {...fieldProps}
                />
              )}
            />
          )}
          <Controller
            name="name"
            control={control}
            shouldUnregister
            rules={{
              validate: combineValidators(
                validators.required({
                  text: REFERENCE_NAME_REQUIRED_ERROR,
                }),
                validators.maxLength({ maxLength: 300 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize
                {...fieldProps}
                label={REFERENCE_NAME_FIELD_NAME}
              />
            )}
          />

          <Controller
            name="number"
            control={control}
            rules={{
              required: REFERENCE_PHONE_REQUIRED_ERROR,
              validate: combineValidators(
                validators.phoneLength(),
                validators.phoneFormat(),
                validatePhoneUnicity('alternative')
              ),
            }}
            render={(fieldProps) => (
              <InputPhone
                label={REFERENCE_PHONE_FIELD_NAME}
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onBlur: () => {
                    const { error } = fieldProps.fieldState;
                    const { value } = fieldProps.field;

                    onPhoneUpdate(!error ? value : undefined, 'alternative');
                    fieldProps.field.onBlur();
                  },
                }}
              />
            )}
          />
        </div>
      </Screen>
    </form>
  );
};
