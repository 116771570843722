import { useEffect, useCallback, useState } from 'react';

export const useResendCountdown = (
  onDone?: () => void
): [number, (countdownTime: number) => void, () => void] => {
  const [timer, setTimer] = useState(0);
  const [isDone, setIsDone] = useState<boolean>();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (timer > 0) {
      timeoutId = setTimeout(() => {
        setTimer((time) => {
          const value = time - 1;
          if (value === 0) {
            setIsDone(true);
          }
          return value;
        });
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (isDone && onDone) {
      onDone();
    }
  }, [onDone, isDone]);

  const startCountdown = useCallback(
    (countdownTime: number) => {
      setIsDone(false);
      setTimer(countdownTime);
    },
    [setTimer]
  );

  const cancelCountdown = useCallback(() => {
    setTimer(0);
  }, [setTimer]);
  return [timer, startCountdown, cancelCountdown];
};
