export const agentsNewsKeys = {
  all: () => ['agents-news'] as const,
  list: () => [...agentsNewsKeys.all(), 'list'] as const,
  newsItem: (id: string) => [...agentsNewsKeys.all(), id] as const,
  blockingNewsItem: (id: string) =>
    [...agentsNewsKeys.all(), 'blocking', id] as const,

  surveyAll: () => ['agent-survey-data'] as const,
  surveyItem: (id: string | null) =>
    [...agentsNewsKeys.surveyAll(), id] as const,
};
