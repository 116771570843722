import { type QueryClient } from '@tanstack/react-query';

import { AgentNewsInfo, type AgentNewsDetails } from '../agent-news';
import { agentsNewsKeys } from '../api/keys';

export const markReadNewsInQueryCache = (
  queryClient: QueryClient,
  ids: string[]
) => {
  const idsSet = new Set(ids);
  const list = queryClient.getQueryData<AgentNewsInfo[]>(agentsNewsKeys.list());

  if (list) {
    const items = list.map((item) =>
      !item.isRead && idsSet.has(item.documentId)
        ? { ...item, isRead: true }
        : item
    );

    queryClient.setQueryData<AgentNewsInfo[]>(agentsNewsKeys.list(), items);
  }

  ids.forEach((id) => {
    const newsItem = queryClient.getQueryData<AgentNewsDetails>(
      agentsNewsKeys.newsItem(id)
    );

    if (newsItem) {
      queryClient.setQueryData<AgentNewsDetails>(agentsNewsKeys.newsItem(id), {
        ...newsItem,
        isRead: true,
      });
    }
  });
};
