import {
  Controller,
  FieldErrors,
  FormProvider,
  useForm,
} from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Select } from '@breeze/rhf-adapters';

import { SELECT_REQUIRED_ERROR } from '../../constants/field-errors';
import { EMPLOYMENT_STATUS_FIELD_NAME } from '../../constants/field-names';
import { employmentStatusOptions } from '../../lib/employment-status-options';
import { EmploymentDetailsFormValues } from '../../model/form-values';
import { IndustryDetails } from '../industry-details/industry-details';
import { UnemployedDetails } from '../unemployed-details/unemployed-details';

type Props = Readonly<{
  name: string;
  initialValue?: Partial<EmploymentDetailsFormValues>;
  onPrev?: (
    values: Partial<EmploymentDetailsFormValues>,
    errors: FieldErrors<EmploymentDetailsFormValues>
  ) => void;
  onSubmit: (values: EmploymentDetailsFormValues) => void;
  onFieldCompleted?: (
    value: Partial<EmploymentDetailsFormValues>,
    errors: FieldErrors<EmploymentDetailsFormValues>
  ) => void;
  onClose: () => void;
}>;

export const EmploymentDetails = ({
  name,
  initialValue,
  onFieldCompleted,
  onSubmit,
  onClose,
  onPrev,
}: Props) => {
  const methods = useForm<EmploymentDetailsFormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues, watch } = methods;

  useRevalidate(methods);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<EmploymentDetailsFormValues>(methods);

    onPrev?.(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<EmploymentDetailsFormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const isUnemployed = watch('employmentStatus') === 'UNEMPLOYED';

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev && handleClickButtonPrev} />}
      >
        <div>
          <ScreenTitle title="Employment details" />
          <FormProvider {...methods}>
            <Controller
              control={control}
              name="employmentStatus"
              rules={{
                required: SELECT_REQUIRED_ERROR,
              }}
              render={(fieldProps) => (
                <Select
                  label={EMPLOYMENT_STATUS_FIELD_NAME}
                  options={employmentStatusOptions}
                  {...fieldProps}
                />
              )}
            />
            {!isUnemployed ? <IndustryDetails /> : <UnemployedDetails />}
          </FormProvider>
        </div>
      </Screen>
    </form>
  );
};
