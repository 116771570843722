import { ChallengeParameters } from '~entities/auth';

import { HttpError } from '~shared/errors';

import { createMachine, assign } from 'xstate';

interface ConfirmationContext {
  error?: Error | HttpError;
  parameters?: ChallengeParameters;
}

type ConfirmationEvent =
  | { type: 'ANSWER'; value: string }
  | { type: 'CODE_CHANGE' };

type ConfirmationTypeState =
  | {
      value: 'idle';
      context: ConfirmationContext & { error: undefined };
    }
  | {
      value: 'confirmation';
      context: ConfirmationContext & { error: undefined };
    }
  | {
      value: 'confirmed';
      context: ConfirmationContext & {
        error: undefined;
      };
    }
  | {
      value: 'error';
      context: ConfirmationContext & { error: Error };
    }
  | {
      value: 'confirmationError';
      context: ConfirmationContext & { error: Error };
    }
  | {
      value: 'unexpectedError';
      context: ConfirmationContext & { error: Error };
    }
  | {
      value: 'authorizationError';
      context: ConfirmationContext & { error: Error };
    }
  | {
      value: 'attemptsLimitError';
      context: ConfirmationContext & { error: Error };
    };

export const confirmationStatesMachine = createMachine<
  ConfirmationContext,
  ConfirmationEvent,
  ConfirmationTypeState
>({
  schema: {
    services: {} as {
      respond: { data: undefined };
    },
  },
  predictableActionArguments: true,
  id: 'confirmation-process',
  initial: 'idle',
  context: { error: undefined },
  states: {
    idle: {
      entry: assign({ error: undefined }),
      on: {
        ANSWER: { target: 'confirmation' },
      },
    },
    confirmation: {
      invoke: {
        src: 'respond',
        onDone: 'confirmed',
        onError: {
          target: 'error',
          actions: assign<
            ConfirmationContext,
            {
              type: string;
              data: {
                parameters: ChallengeParameters;
              } & Error;
            }
          >({
            error: (_context, event) => event.data,
            parameters: (_context, event) => event.data.parameters,
          }),
        },
      },
    },
    confirmed: {},
    error: {
      always: [
        {
          target: 'confirmationError',
          cond: 'isConfirmationError',
        },
        {
          target: 'attemptsLimitError',
          cond: 'isAttemptsLimitError',
        },
        {
          target: 'authorizationError',
          cond: 'isAuthorizationError',
        },
        { target: 'unexpectedError' },
      ],
    },
    confirmationError: {
      on: { CODE_CHANGE: { target: 'idle' } },
    },
    unexpectedError: {
      on: { CODE_CHANGE: { target: 'idle' } },
    },
    attemptsLimitError: {
      on: { CODE_CHANGE: { target: 'idle' } },
    },
    authorizationError: {},
  },
});
