import type { RecoveryMapping } from '~shared/formatters/mappings';
import { formatByMapping } from '~shared/formatters/mappings';

import type { PersonPatchUpdatePayload } from '../api/person-api';

export type PersonPatchScreensMapping<T> = RecoveryMapping<
  T,
  PersonPatchUpdatePayload
>;

export type PersonPatchFormatter<T> = {
  (
    person: PersonPatchUpdatePayload,
    values: Partial<T>,
    formValues?: Record<string, unknown>
  ): PersonPatchUpdatePayload;
};

export function patchFormatValuesByMapping<
  StepValues extends Record<string, unknown>,
>(
  person: PersonPatchUpdatePayload,
  stepValues: Partial<StepValues> = {},
  mapping: PersonPatchScreensMapping<StepValues>
) {
  return formatByMapping(person, stepValues, mapping);
}
