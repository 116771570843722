import {
  formatLoanCalculator,
  LoanCalculatorFormValues,
} from '~widgets/loan-calculator';

import {
  ApplicationDetails,
  ApplicationLoanDetails,
} from '~entities/application';

export function buildApplicationLoanDetails(
  applicationData: ApplicationDetails,
  loanCalculator?: LoanCalculatorFormValues
): ApplicationLoanDetails {
  if (loanCalculator) {
    return formatLoanCalculator(loanCalculator);
  }

  return {
    orderItems: applicationData.orderItems,
    requestedProduct: {
      type: 'POS_LOAN',
      ...applicationData.requestedProduct,
    },
    offer: applicationData.offer,
    promoCode: applicationData.promoCode,
  };
}
