import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  agentNewsApi,
  type AgentNewsDetails,
  type AgentSurveyData,
  isItemImportant,
} from '~entities/agent-news';

import { GeneralNewsItem } from './general-news-item';
import { SurveyNewsItem } from './survey-news-item';

type Props = {
  item: AgentNewsDetails;
  surveyData?: AgentSurveyData;
  onMarkRead?: () => void;
  isBlocking: boolean;
};

export const AgentNewsItemScreen = ({
  item,
  surveyData,
  onMarkRead,
  isBlocking,
}: Props) => {
  const navigate = useNavigate();
  const { mutate } = agentNewsApi.useReadAgentNews();

  const handleNewsItemRead = useCallback(
    (id: string) => {
      mutate([id]);
    },
    [mutate]
  );

  const handleGeneralNewsRead = useCallback(
    (id: string) => {
      handleNewsItemRead(id);
      onMarkRead?.();
    },
    [handleNewsItemRead, onMarkRead]
  );

  const handleClose = useCallback(() => {
    if (isBlocking) {
      onMarkRead?.();
    } else {
      navigate('/agent-news', { replace: true });
    }
  }, [isBlocking, onMarkRead, navigate]);

  useEffect(() => {
    /**
     * For survey items, there are two requests - completing the survey and marking the news item as read.
     * There can be situations where there is an unstable internet connection, the survey request is completed but the read request fails.
     * In this case, when opening such an item (whether it's blocking or opened from the list again), we need to try making the read request again.
     */
    if (surveyData?.status === 'FINISHED' && !item.isRead) {
      handleGeneralNewsRead(item.documentId);
    }
  }, [surveyData, item, handleGeneralNewsRead]);

  useEffect(() => {
    /**
     * For not important news, we mark it as been read when it's opened.
     */
    if (!isItemImportant(item) && !item.isRead) {
      handleNewsItemRead(item.documentId);
    }
  }, [item, handleNewsItemRead]);

  return item.surveyQuestions.length === 0 ? (
    <GeneralNewsItem item={item} onMarkRead={handleGeneralNewsRead} />
  ) : (
    <SurveyNewsItem
      item={item}
      isBlocking={isBlocking}
      surveyData={surveyData}
      onSurveyRead={handleNewsItemRead}
      onSurveyComplete={handleClose}
    />
  );
};
