import {
  ClientAssessmentFormValues,
  formatClientAssessment,
} from '~widgets/client-assessment';

import { Application, ApplicationFormatter } from '~entities/application';
import {
  PersonPatchUpdatePayload,
  PersonPatchFormatter,
} from '~entities/person';

import { getValidValues } from '~shared/lib/get-valid-values';

import identity from '@tinkoff/utils/function/identity';
import isEmpty from '@tinkoff/utils/is/empty';

import { FormErrors, FormValues } from './form-values';
import {
  ApplicationStepName,
  applicationStepNames,
  PersonDetailsStepName,
  personDetailsStepNames,
  StepName,
} from './step-names';

import { patchFormatEmploymentDetails } from '../employment-details/lib/formatter';
import { patchFormatFinances } from '../finances';
import { patchFormatPersonalReference } from '../personal-reference';
import { formatProfile } from '../profile';
import { patchFormatWorkReference } from '../work-reference';

export const personScreenFormatters: Record<
  PersonDetailsStepName,
  PersonPatchFormatter<unknown>
> = {
  [personDetailsStepNames.profile]: formatProfile,
  [personDetailsStepNames.finances]: patchFormatFinances,
  [personDetailsStepNames.employmentDetails]: patchFormatEmploymentDetails,
  [personDetailsStepNames.workReference]: patchFormatWorkReference,
  [personDetailsStepNames.personalReference]: patchFormatPersonalReference,
  [personDetailsStepNames.clientPhoto]: identity,
};

export const applicationScreenFormatters: Record<
  ApplicationStepName,
  ApplicationFormatter<ClientAssessmentFormValues>
> = {
  [applicationStepNames.clientAssessment]: formatClientAssessment,
};

type ScreensValues = Record<StepName, any>;

export const formatPersonScreenValues = (
  name: StepName,
  screenValue: Partial<FormValues[typeof name]>,
  errors: Partial<FormErrors[typeof name]>
): PersonPatchUpdatePayload => {
  const person = {} as PersonPatchUpdatePayload;

  if (!(name in personDetailsStepNames)) {
    return person;
  }

  const personStepName = name as PersonDetailsStepName;

  const formatter = personScreenFormatters[personStepName];
  const validScreenValue = getValidValues(screenValue, errors ?? {});

  return formatter(person, isEmpty(validScreenValue) ? {} : validScreenValue);
};

export const formatApplicationScreenValues = (
  names: ApplicationStepName[],
  values: Partial<ScreensValues>,
  errors: Partial<FormErrors>
): Application => {
  let application = {
    formFillingStatus: 'IN_PROGRESS',
    requestedProduct: { type: 'POS_LOAN' },
  } as Application;

  for (const screenName of names) {
    const screenValue = values[screenName];
    const validScreenValue = getValidValues(
      screenValue,
      errors[screenName] ?? ({} as any)
    );

    application = applicationScreenFormatters[screenName](
      application,
      isEmpty(validScreenValue) ? {} : validScreenValue
    );
  }

  return application;
};
