import type {
  PersonPatchFormatter,
  PersonPatchScreensMapping,
} from '~entities/person/lib';
import { patchFormatValuesByMapping } from '~entities/person/lib';

import { EmploymentDetailsFormValues } from '../model/form-values';

const employmentDetailsMapping: PersonPatchScreensMapping<EmploymentDetailsFormValues> =
  {
    employmentStatus: { path: ['work', 'employmentStatus'] },
    natureOfWork: { path: ['work', 'natureOfWork'] },
    natureOfWorkOther: { path: ['work', 'natureOfWorkOther'] },
    unemployedReason: { path: ['work', 'unemployedReason'] },
    unemployedReasonOther: {
      path: ['work', 'unemployedReasonOther'],
    },
  };

export const patchFormatEmploymentDetails: PersonPatchFormatter<
  EmploymentDetailsFormValues
> = (person, screenValues) => {
  return patchFormatValuesByMapping(
    person,
    screenValues,
    employmentDetailsMapping
  );
};
