import { Controller, useFormContext } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Input, Select } from '@breeze/rhf-adapters';

import {
  NATURE_OF_WORK_OTHER_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../../constants/field-errors';
import {
  NATURE_OF_WORK_FIELD_NAME,
  NATURE_OF_WORK_OTHER_FIELD_NAME,
} from '../../constants/field-names';
import { natureOfWorkOptions } from '../../lib/nature-of-work-options';
import { EmploymentDetailsFormValues } from '../../model/form-values';

export const IndustryDetails = () => {
  const { control, watch } = useFormContext<EmploymentDetailsFormValues>();
  const natureOfWork = watch('natureOfWork');

  return (
    <>
      <Controller
        control={control}
        name="natureOfWork"
        rules={{
          required: SELECT_REQUIRED_ERROR,
        }}
        render={(fieldProps) => (
          <Select
            label={NATURE_OF_WORK_FIELD_NAME}
            options={natureOfWorkOptions}
            {...fieldProps}
          />
        )}
      />
      {natureOfWork === 'Other' && (
        <Controller
          name="natureOfWorkOther"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: NATURE_OF_WORK_OTHER_REQUIRED_ERROR,
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.latinOnly()
            ),
          }}
          render={(fieldProps) => (
            <Input label={NATURE_OF_WORK_OTHER_FIELD_NAME} {...fieldProps} />
          )}
        />
      )}
    </>
  );
};
