import type {
  PersonPatchFormatter,
  PersonPatchScreensMapping,
} from '~entities/person/lib';
import { patchFormatValuesByMapping } from '~entities/person/lib';

import { FinancesFormValues } from '../model/form-values';

const financesMapping: PersonPatchScreensMapping<FinancesFormValues> = {
  personMonthlyIncome: { path: ['finance', 'personMonthlyIncome'] },
};

export const patchFormatFinances: PersonPatchFormatter<FinancesFormValues> = (
  person,
  screenValues
) => {
  return patchFormatValuesByMapping(person, screenValues, financesMapping);
};
