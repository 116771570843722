import { useDi } from '~app/providers/di-provider';

import { useQueuedMutation } from '~shared/react-query-helpers';

import type { UseMutationOptions } from '@tanstack/react-query';
import { skipToken, useQuery } from '@tanstack/react-query';

import { PersonPatchUpdatePayload } from './person-api';
import { personKeys } from './person-keys';

import type { Person } from '../model';

export const useGetPersonQuery = (options?: { shouldSkip?: boolean }) => {
  const { personApi } = useDi();

  return useQuery({
    queryKey: personKeys.get(),
    queryFn: options?.shouldSkip ? skipToken : () => personApi.getPerson(),
    gcTime: 0,
  });
};

export const useUpdatePersonMutation = (
  options?: UseMutationOptions<Person, Error, Partial<Person>>
) => {
  const { personApi } = useDi();

  return useQueuedMutation({
    mutationFn: (payload: Partial<Person>) => personApi.updatePerson(payload),
    ...options,
  });
};

export const useCheckProfileQuery = () => {
  const { personApi } = useDi();

  return useQuery({
    queryKey: personKeys.checkProfile(),
    queryFn: async () => {
      try {
        return await personApi.checkProfile();
      } catch (_err) {
        return { profileFilled: false };
      }
    },
    gcTime: 0,
  });
};

export const usePatchUpdatePersonMutation = () => {
  const { personApi } = useDi();

  return useQueuedMutation({
    mutationFn: (payload: PersonPatchUpdatePayload) =>
      personApi.patchUpdatePerson(payload),
  });
};
