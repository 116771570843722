import pick from '@tinkoff/utils/object/pick';

import type { AgentNewsDetails, AgentNewsInfo } from '../agent-news';
import type { AgentNewsDetailsDto, AgentNewsInfoDto } from '../api';
import {
  isMediaComponent,
  isCheckboxSurveyQuestion,
  isRadioSurveyQuestion,
  isAgentNewsInfoDtoV4,
  isAgentNewsDetailsDtoV4,
  type AgentNewsDetailsDtoV5,
} from '../api/api-types';

export const getSurveyControlOptions = (options: string): string[] => {
  return options.split('\n');
};

const mapArticle = (article: AgentNewsDetailsDtoV5['article']) =>
  article.map((item): AgentNewsDetails['article'][number] => {
    if (isMediaComponent(item)) {
      return {
        type: 'media',
        content: item.content,
      };
    }
    return {
      type: 'text',
      content: item.content,
    };
  });

const mapSurveyQuestions = (
  surveyQuestions: AgentNewsDetailsDtoV5['surveyQuestions']
) =>
  surveyQuestions.map((item): AgentNewsDetails['surveyQuestions'][number] => {
    if (isCheckboxSurveyQuestion(item)) {
      return {
        type: 'checkbox-group',
        question: item.title,
        options: getSurveyControlOptions(item.options),
      };
    }
    if (isRadioSurveyQuestion(item)) {
      return {
        type: 'radio-group',
        question: item.title,
        options: getSurveyControlOptions(item.options),
      };
    }

    return {
      type: 'text-area',
      question: item.title,
      placeholder: item.placeholder,
    };
  });

export const getItemDetailsFromDto = (
  dtoItem: AgentNewsDetailsDto
): AgentNewsDetails => {
  const attributes = isAgentNewsDetailsDtoV4(dtoItem)
    ? dtoItem.attributes
    : dtoItem;

  return {
    documentId: dtoItem.documentId ?? dtoItem.id,
    ...pick(
      ['importance', 'publishedAt', 'title', 'isRead', 'surveyDueDate'],
      attributes
    ),
    article: mapArticle(attributes.article),
    surveyQuestions: mapSurveyQuestions(attributes.surveyQuestions),
  };
};

export const getItemInfoFromDto = (
  dtoItem: AgentNewsInfoDto
): AgentNewsInfo => {
  if (isAgentNewsInfoDtoV4(dtoItem)) {
    return {
      ...dtoItem.attributes,
      documentId: dtoItem.documentId ?? dtoItem.id,
    };
  }

  const { id, ...rest } = dtoItem;
  return {
    ...rest,
    documentId: dtoItem.documentId ?? id,
  };
};
