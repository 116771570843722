import { Controller, FieldErrors, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Input, InputCapitalize, InputDate } from '@breeze/rhf-adapters';

import {
  EMAIL_REQUIRED_ERROR,
  DATE_OF_BIRTH_REQUIRED_ERROR,
  FIRST_NAME_REQUIRED_ERROR,
  LAST_NAME_REQUIRED_ERROR,
} from '../constants/field-errors';
import {
  BIRTH_DATE_FIELD_NAME,
  EMAIL_FIELD_NAME,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  MIDDLE_NAME_FIELD_NAME,
  PREFFERED_NAME_FIELD_NAME,
} from '../constants/field-names';
import { ProfileFormValues } from '../model/form-values';

type Props = Readonly<{
  name: string;
  initialValue?: Partial<ProfileFormValues>;
  onPrev?: (
    values: Partial<ProfileFormValues>,
    errors: FieldErrors<ProfileFormValues>
  ) => void;
  onSubmit: (values: ProfileFormValues) => void;
  onFieldCompleted?: (
    value: Partial<ProfileFormValues>,
    errors: FieldErrors<ProfileFormValues>
  ) => void;
  onClose: () => void;
}>;

export const Profile = ({
  name,
  initialValue,
  onFieldCompleted,
  onSubmit,
  onClose,
  onPrev,
}: Props) => {
  const methods = useForm<ProfileFormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues } = methods;

  useRevalidate(methods);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<ProfileFormValues>(methods);

    onPrev?.(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<ProfileFormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev && handleClickButtonPrev} />}
      >
        <div>
          <ScreenTitle title="Personal details" />

          <Controller
            name="firstName"
            control={control}
            rules={{
              validate: combineValidators(
                validators.required({
                  text: FIRST_NAME_REQUIRED_ERROR,
                }),
                validators.maxLength({ maxLength: 300 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize label={FIRST_NAME_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="middleName"
            control={control}
            rules={{
              validate: combineValidators(
                validators.maxLength({ maxLength: 300 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize label={MIDDLE_NAME_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              validate: combineValidators(
                validators.required({ text: LAST_NAME_REQUIRED_ERROR }),
                validators.maxLength({ maxLength: 300 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize label={LAST_NAME_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="birthDate"
            control={control}
            rules={{
              required: DATE_OF_BIRTH_REQUIRED_ERROR,
              validate: combineValidators(
                validators.isDate(),
                validators.ageRange({
                  minAge: 0,
                  maxAge: 130,
                })
              ),
            }}
            render={(fieldProps) => (
              <InputDate
                label={BIRTH_DATE_FIELD_NAME}
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  value: fieldProps.field.value || '',
                  onChange: (_event, params) => {
                    fieldProps.field.onChange(params.maskedValue);
                  },
                }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              validate: combineValidators(
                validators.required({ text: EMAIL_REQUIRED_ERROR }),
                validators.email({
                  invalidSymbolsText: 'Invalid characters',
                  invalidFormatText: 'The format must be name@email.com',
                })
              ),
            }}
            render={(fieldProps) => (
              <Input label={EMAIL_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="preferredName"
            control={control}
            rules={{
              validate: combineValidators(
                validators.maxLength({ maxLength: 15 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize
                label={PREFFERED_NAME_FIELD_NAME}
                {...fieldProps}
              />
            )}
          />
        </div>
      </Screen>
    </form>
  );
};
