import type { HttpError } from '~shared/errors';
import { isHttpError } from '~shared/errors';

import { ChallengeParameters } from './gateway/gateway-auth-api';

export class ChallengeConfirmationError extends Error {
  name = 'AUTH_CHALLENGE_CONFIRMATION_ERROR';
  parameters;

  constructor(message?: string, parameters?: Partial<ChallengeParameters>) {
    super(message);
    this.parameters = parameters;
  }
}

export function isChallengeConfirmationError(
  error: Error
): error is ChallengeConfirmationError {
  return error.name === 'AUTH_CHALLENGE_CONFIRMATION_ERROR';
}

export enum FindOrCreateApplicationErrorCode {
  activeApplication = 'CAN_NOT_CREATE_DUE_TO_ACTIVE_APPLICATION',
  activeLoanWithOffer = 'CAN_NOT_CREATE_DUE_TO_ACTIVE_LOAN_WITH_OFFER',
  activeAgreement = 'CAN_NOT_CREATE_DUE_TO_ACTIVE_AGREEMENT',
  rejectionCooldown = 'CAN_NOT_CREATE_DUE_TO_REJECTION_COOLDOWN',
  inactiveOffer = 'CAN_NOT_CREATE_DUE_TO_INACTIVE_OFFER',
}

export type FindOrCreateApplicationError = HttpError & {
  code: FindOrCreateApplicationErrorCode;
};

export function isFindOrCreateApplicationError(
  error: Error
): error is FindOrCreateApplicationError {
  return isHttpError(error) && error.status === 400 && !!error.code;
}

export function isUnauthorizedError(error: Error): boolean {
  return isHttpError(error) && error.status === 401;
}

export class AttemptsLimitError extends Error {
  name = 'ATTEMPTS_LIMIT_REACHED_ERROR';
}

export function isAttemptsLimitError(
  error: Error
): error is AttemptsLimitError {
  return error.name === 'ATTEMPTS_LIMIT_REACHED_ERROR';
}
