import type { EmploymentStatusValue } from '~widgets/loan-application-form/employment/lib';

import { createMachine } from 'xstate';

import type { StepName } from '../lib';
import { stepNames } from '../lib';

export const StepFlowMachine = (
  initialStep: StepName
): ReturnType<typeof createMachine> => {
  return createMachine({
    id: 'xsell-short-application-form',
    predictableActionArguments: true,
    initial: initialStep,
    states: {
      [stepNames.profile]: {
        on: {
          next: {
            target: stepNames.finances,
          },
        },
      },
      [stepNames.finances]: {
        on: {
          next: {
            target: stepNames.employmentDetails,
          },
        },
      },
      [stepNames.employmentDetails]: {
        on: {
          next: [
            {
              target: stepNames.workReference,
              cond: (formValues: any) =>
                !['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
            {
              target: stepNames.personalReference,
              cond: (formValues: any) =>
                ['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
          ],
        },
      },
      [stepNames.workReference]: {
        on: {
          next: {
            target: stepNames.personalReference,
          },
        },
      },
      [stepNames.personalReference]: {
        on: {
          next: {
            target: stepNames.clientPhoto,
          },
        },
      },
      [stepNames.clientPhoto]: {
        on: {
          next: {
            target: stepNames.clientAssessment,
          },
        },
      },
      [stepNames.clientAssessment]: {
        type: 'final',
      },
    },
  });
};
