import { Controller, useFormContext } from 'react-hook-form';

import { UNEMPLOYED_REASON_OTHER_FIELD_NAME } from '~widgets/loan-application-form/employment/constants/field-names';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Input, RadioGroup } from '@breeze/rhf-adapters';

import { UNEMPLOYED_REASON_OTHER_REQUIRED_ERROR } from '../../constants/field-errors';
import { unemployedReasonOptions } from '../../lib/unemployed-reason-options';
import { EmploymentDetailsFormValues } from '../../model/form-values';

export const UnemployedDetails = () => {
  const { control, watch } = useFormContext<EmploymentDetailsFormValues>();
  const unemployedReason = watch('unemployedReason');
  return (
    <>
      <Controller
        name="unemployedReason"
        control={control}
        rules={{
          required: 'Select an option',
        }}
        render={(fieldProps) => (
          <RadioGroup
            {...fieldProps}
            options={unemployedReasonOptions}
            vertical
            wide
          />
        )}
      />
      {unemployedReason === 'Other' && (
        <Controller
          name="unemployedReasonOther"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: UNEMPLOYED_REASON_OTHER_REQUIRED_ERROR,
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.latinOnly()
            ),
          }}
          render={(fieldProps) => (
            <Input label={UNEMPLOYED_REASON_OTHER_FIELD_NAME} {...fieldProps} />
          )}
        />
      )}
    </>
  );
};
