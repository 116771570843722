import { useState } from 'react';

import { AgentSurveyForm } from '~widgets/agent-survey-form';
import type { SurveyFormValues } from '~widgets/agent-survey-form/types';

import {
  type AgentNewsDetails,
  type AgentSurveyData,
  isSurveyExpired,
  isItemImportant,
  agentNewsApi,
} from '~entities/agent-news';

import errorImage from '~shared/images/error.svg';
import { Loader } from '~shared/ui/loader';
import { ScreenTitle } from '~shared/ui/screen-title';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';

import { Article } from './article';

import lunaSrc from '../images/luna.png';

type Props = {
  item: AgentNewsDetails;
  surveyData?: AgentSurveyData;
  isBlocking: boolean;
  onSurveyRead?: (id: string) => void;
  onSurveyComplete: () => void;
};

export const SurveyNewsItem = ({
  item,
  surveyData,
  isBlocking,
  onSurveyRead,
  onSurveyComplete,
}: Props) => {
  const [surveyFormShown, setSurveyFormShown] = useState(false);
  const [isFinalSubmitLoading, setIsFinalSubmitLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSubmitError, setIsFormSubmitError] = useState(false);
  const { mutate: update, finalMutateAsync: finalUpdate } =
    agentNewsApi.useUpdateSurveyData();

  const handleFormChanged = (data: SurveyFormValues) => {
    if (isFinalSubmitLoading) {
      return;
    }
    update({
      newsId: item.documentId,
      status: 'IN_PROGRESS',
      data,
    });
  };
  const handleFinalSubmit = async (data: SurveyFormValues) => {
    if (isFinalSubmitLoading) {
      return;
    }
    setIsFinalSubmitLoading(true);
    try {
      await finalUpdate({ newsId: item.documentId, status: 'FINISHED', data });
      setIsFinalSubmitLoading(false);
      setIsFormSubmitted(true);
    } catch (_error) {
      setIsFinalSubmitLoading(false);
      setIsFormSubmitError(true);
    }

    if (isItemImportant(item)) {
      onSurveyRead?.(item.documentId);
    }
  };

  const getSurveyControl = () => {
    if (surveyData?.status === 'FINISHED') {
      return (
        <NotificationInline
          type="success"
          animateFirstRender={false}
          timer={false}
          showClose={false}
          withIcon
          title="You've already completed this survey"
        />
      );
    }
    if (isSurveyExpired(item)) {
      return (
        <NotificationInline
          type="info"
          animateFirstRender={false}
          timer={false}
          showClose={false}
          withIcon
          title="This survey is expired. You can't complete it anymore."
        />
      );
    }

    return (
      <Button wide theme="primary" onClick={() => setSurveyFormShown(true)}>
        Let’s go!
      </Button>
    );
  };

  if (isFormSubmitted) {
    return (
      <Col alignCross="center" gaps={32}>
        <img src={lunaSrc} width="130" height="130" alt="success" />
        <ScreenTitle title="Thank you for the feedback!" />
        <Button
          size="m"
          wide
          theme="primary"
          onClick={() => onSurveyComplete()}
        >
          {isBlocking ? 'Exit the survey' : 'Back to news'}
        </Button>
      </Col>
    );
  }

  if (isFormSubmitError) {
    return (
      <Col alignCross="center" gaps={32}>
        <img src={errorImage} width="130" height="130" alt="error" />
        <ScreenTitle title="Something went wrong. Please try to complete the survey later." />
        <Button
          size="m"
          wide
          theme="primary"
          onClick={() => onSurveyComplete()}
        >
          {isBlocking ? 'Exit the survey' : 'Back to news'}
        </Button>
      </Col>
    );
  }

  return (
    <Col alignCross="stretch">
      {surveyFormShown ? (
        <AgentSurveyForm
          questions={item.surveyQuestions}
          initialValues={surveyData?.data || {}}
          onSurveyExit={() => setSurveyFormShown(false)}
          onFormChanged={handleFormChanged}
          onFinalSubmit={handleFinalSubmit}
        />
      ) : (
        <>
          <Article item={item} />
          {getSurveyControl()}
        </>
      )}
      {isFinalSubmitLoading && <Loader overlay semiTransparentBg />}
    </Col>
  );
};
