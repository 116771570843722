import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import {
  Input,
  InputCapitalize,
  Select,
  Multiselect,
} from '@breeze/rhf-adapters';

import {
  NICKNAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  CITIZENSHIP_FIELD_NAME,
  PLACE_OF_BIRTH_FIELD_NAME,
  MARITAL_STATUS_FIELD_NAME,
  PREFERRED_LANGUAGE,
  GENDER_FIELD_NAME,
  PLACE_OF_BIRTH_REQUIRED,
  SELECT_REQUIRED,
  MULTISELECT_REQUIRED,
} from '../constants';
import {
  citizenshipOptions,
  preferredLanguageOptions,
  civilStatusOptions,
  genderOptions,
} from '../lib';
import type { FormValues } from '../types';

type Props = {
  name: string;
  initialValue?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
};

export const PersonalDetails = (props: Props) => {
  const {
    name,
    initialValue = {},
    onSubmit,
    onPrev,
    onFieldCompleted,
    onClose,
  } = props;

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });

  useRevalidate(methods);

  const { control, handleSubmit, getValues } = methods;

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);
    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <ScreenTitle title="Personal details" />
        <Controller
          name="email"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({ text: 'Enter customer’s email' }),
              validators.email({
                invalidSymbolsText: 'Invalid characters',
                invalidFormatText: 'The format must be name@email.com',
              })
            ),
          }}
          render={(fieldProps) => (
            <Input label={EMAIL_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="citizenship"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={CITIZENSHIP_FIELD_NAME}
              options={citizenshipOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="gender"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={GENDER_FIELD_NAME}
              options={genderOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="placeOfBirth"
          control={control}
          rules={{
            validate: combineValidators(
              validators.required({
                text: PLACE_OF_BIRTH_REQUIRED,
              }),
              validators.maxLength({ maxLength: 150 }),
              validators.filipinoGeo()
            ),
          }}
          render={(fieldProps) => (
            <Input label={PLACE_OF_BIRTH_FIELD_NAME} {...fieldProps} />
          )}
        />
        <Controller
          name="civilStatus"
          control={control}
          rules={{
            required: SELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Select
              label={MARITAL_STATUS_FIELD_NAME}
              options={civilStatusOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="preferredLanguages"
          control={control}
          rules={{
            required: MULTISELECT_REQUIRED,
          }}
          render={(fieldProps) => (
            <Multiselect
              label={PREFERRED_LANGUAGE}
              options={preferredLanguageOptions}
              {...fieldProps}
            />
          )}
        />
        <Controller
          name="nickname"
          control={control}
          rules={{
            validate: combineValidators(
              validators.maxLength({ maxLength: 15 }),
              validators.filipinoName()
            ),
          }}
          render={(fieldProps) => (
            <InputCapitalize label={NICKNAME_FIELD_NAME} {...fieldProps} />
          )}
        />
      </Screen>
    </form>
  );
};
