import { type BlocksContent } from '@strapi/blocks-react-renderer';

export type Importance = 'unimportant' | 'important-non-blocking' | 'blocking';

type Extensions = '.jpg' | '.jpeg' | '.png' | '.mp4';

export type Media = {
  alternativeText?: string;
  ext: Extensions;
  url: string;
  width?: number;
  height?: number;
};

type InfoAttributes = {
  publishedAt: string;
  title: string;
  importance: Importance;
  isRead: boolean;
  // 'yyyy-MM-dd'
  surveyDueDate: string | null;
};

export type AgentNewsInfoDtoV4 = {
  /**
   * @deprecated use documentId instead
   */
  id: string;
  documentId?: string;
  attributes: InfoAttributes;
};

export type AgentNewsInfoDtov5 = {
  /**
   * @deprecated use documentId instead
   */
  id: string;
  documentId: string;
} & InfoAttributes;

export type AgentNewsInfoDto = AgentNewsInfoDtoV4 | AgentNewsInfoDtov5;

export function isAgentNewsInfoDtoV4(
  dto: AgentNewsInfoDto
): dto is AgentNewsInfoDtoV4 {
  return 'attributes' in dto;
}

type TextBlockComponent = {
  __component: 'agent-news.text-block';
  content: BlocksContent;
};

type MediaBlockComponent = {
  __component: 'agent-news.media-block';
  content: Media[];
};

type CheckboxGroupQuestion = {
  __component: 'survey-controls.checkbox-group';
  title: string;
  options: string;
};
type RadioGroupQuestion = {
  __component: 'survey-controls.radio-group';
  title: string;
  options: string;
};
type TextAreaQuestion = {
  __component: 'survey-controls.text-area';
  title: string;
  placeholder?: string;
};

export type SurveyQuestionDto =
  | CheckboxGroupQuestion
  | RadioGroupQuestion
  | TextAreaQuestion;

export type AgentNewsDetailsDtoV4 = {
  id: string;
  documentId?: string;
  attributes: InfoAttributes & {
    article: (TextBlockComponent | MediaBlockComponent)[];
    surveyQuestions: SurveyQuestionDto[];
  };
};

export type AgentNewsDetailsDtoV5 = {
  /**
   * @deprecated use documentId instead
   */
  id: string;
  documentId: string;
  article: (TextBlockComponent | MediaBlockComponent)[];
  surveyQuestions: SurveyQuestionDto[];
} & InfoAttributes;

export type AgentNewsDetailsDto = AgentNewsDetailsDtoV4 | AgentNewsDetailsDtoV5;

export function isAgentNewsDetailsDtoV4(
  dto: AgentNewsDetailsDto
): dto is AgentNewsDetailsDtoV4 {
  return 'attributes' in dto;
}

export type SurveyDataPayload = {
  newsId: string;
  status: 'IN_PROGRESS' | 'FINISHED';
  data: {
    [question: string]: string | string[];
  };
};

export const isMediaComponent = (
  component: TextBlockComponent | MediaBlockComponent
): component is MediaBlockComponent => {
  return component.__component === 'agent-news.media-block';
};

export const isCheckboxSurveyQuestion = (
  question: SurveyQuestionDto
): question is CheckboxGroupQuestion => {
  return question.__component === 'survey-controls.checkbox-group';
};
export const isRadioSurveyQuestion = (
  question: SurveyQuestionDto
): question is RadioGroupQuestion => {
  return question.__component === 'survey-controls.radio-group';
};
export const isTextAreaQuestion = (
  question: SurveyQuestionDto
): question is TextAreaQuestion => {
  return question.__component === 'survey-controls.text-area';
};
