import { environment } from '~app/environment';

import { AttemptsLimitError } from '~entities/agreement/api/errors';

import { isAuthError } from '~shared/errors';
import type { RequestParams, ScpClient } from '~shared/scp-client';
import { webRequestClient } from '~shared/scp-client';

import type { UserInfo } from '../../auth';
import type {
  AuthApi,
  AuthOtpResponse,
  AuthSuccessResponse,
  InitiateParams,
  RespondParams,
  UpgradeInitiateParams,
  ValidateResponse,
  WebFingerprint,
} from '../auth-api';
import { ChallengeConfirmationError, isUnauthorizedError } from '../errors';

type Options = { clientId: string; host: string };

export interface GatewayOtpResponse {
  session: string;
  challengeKind: string;
  challengeParameters: ChallengeParameters;
}

export type ChallengeParameters = {
  username: string;
  delaySec: string;
  expiresAt: string;
  isNew: boolean;
  remainingOtpEnterAttempts: number;
  remainingOtpSendAttempts: number;
};

function isGatewayOtpResponse(
  response: GatewayOtpResponse | AuthSuccessResponse
): response is GatewayOtpResponse {
  return typeof (response as GatewayOtpResponse)?.session === 'string';
}

export class GatewayAuthApi implements AuthApi {
  private client: ScpClient;
  private clientId: string;
  private host: string;

  private session: string | null = null;
  private username: string | null = null;

  constructor(client: ScpClient, options: Options) {
    this.client = client;
    this.clientId = options.clientId;
    this.host = options.host;
  }

  async initiate(params: InitiateParams) {
    this.session = null;
    this.username = null;

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'initiate',
      payload: {
        clientId: this.clientId,
        ...params,
      },
    });

    this.session = response.session;
    this.username = response.challengeParameters.username;

    return response;
  }

  async respond(params: RespondParams): Promise<AuthSuccessResponse> {
    this.ensureSession();
    let response = {};
    try {
      response = await this.makeRequest<
        GatewayOtpResponse | AuthSuccessResponse
      >({
        method: 'respond',
        payload: {
          clientId: this.clientId,
          session: this.session,
          challengeResponses: {
            answer: params.answer,
            username: this.username,
          },
          userMetadata: params.userMetadata,
        },
      });
    } catch (err) {
      if (isUnauthorizedError(err as Error)) {
        throw new AttemptsLimitError((err as Error).message);
      }
    }

    if (isGatewayOtpResponse(response)) {
      this.session = response.session;
      this.username = response.challengeParameters.username;
      throw new ChallengeConfirmationError(
        'Wrong code',
        response.challengeParameters
      );
    }

    this.session = null;
    this.username = null;

    return response;
  }

  async resendConfirmation() {
    this.ensureSession();

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          repeatChallenge: true,
          username: this.username,
        },
      },
    });

    this.session = response.session;
    this.username = response.challengeParameters.username;

    return response;
  }

  async validate(params: WebFingerprint): Promise<ValidateResponse> {
    return this.makeRequest({
      service: 'merchant_authorizer/tokens',
      method: 'validate',
      payload: params,
      cache: false,
    });
  }

  async upgradeInitiate(
    params: UpgradeInitiateParams
  ): Promise<AuthOtpResponse | AuthSuccessResponse> {
    this.session = null;
    this.username = null;

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'upgrade_initiate',
      payload: {
        clientId: this.clientId,
        ...params,
      },
    });

    if (isGatewayOtpResponse(response)) {
      this.session = response.session;
      this.username = response.challengeParameters.username;
    }

    return response;
  }

  async upgradeRespond(params: RespondParams): Promise<AuthSuccessResponse> {
    this.ensureSession();

    const response = await this.makeRequest<
      GatewayOtpResponse | AuthSuccessResponse
    >({
      method: 'upgrade_respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          answer: params.answer,
          username: this.username,
        },
        userMetadata: params.userMetadata,
      },
    });

    if (isGatewayOtpResponse(response)) {
      this.session = response.session;
      this.username = response.challengeParameters.username;
      throw new ChallengeConfirmationError('Wrong code');
    }

    this.session = null;
    this.username = null;

    return response;
  }

  async confirmOtpSending(): Promise<AuthSuccessResponse> {
    this.ensureSession();
    const response = await this.makeRequest<
      GatewayOtpResponse | AuthSuccessResponse
    >({
      method: 'upgrade_respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          answer: 'true',
          username: this.username,
        },
      },
    });
    if (isGatewayOtpResponse(response)) {
      this.session = response.session;
      this.username = response.challengeParameters.username;
    }
    return response;
  }

  async upgradeResendConfirmation(): Promise<AuthOtpResponse> {
    this.ensureSession();

    const response = await this.makeRequest<GatewayOtpResponse>({
      method: 'upgrade_respond',
      payload: {
        clientId: this.clientId,
        session: this.session,
        challengeResponses: {
          repeatChallenge: true,
        },
      },
    });

    this.session = response.session;
    this.username = response.challengeParameters.username;

    return response;
  }

  async revoke(): Promise<void> {
    try {
      await this.makeRequest<GatewayOtpResponse>({
        method: 'revoke',
        payload: {
          clientId: this.clientId,
        },
      });
      this.session = null;
      this.username = null;
      // eslint-disable-next-line no-empty
    } catch (_err) {}
  }

  async getUserInfo(): Promise<UserInfo> {
    return this.makeRequest<UserInfo>({
      service: 'merchant_authorizer/users',
      method: 'userinfo',
    });
  }

  private ensureSession() {
    if (this.session === null || this.username === null) {
      console.error('Authorization process has not been initialized');
    }
  }

  private makeRequest<T = unknown>(
    params: Partial<RequestParams> & { method: string }
  ) {
    return this.client
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'auth',
        service: 'merchant_authorizer/challenge_flow',
        withLeadingDomain: true,
        withCredentials: true,
        ...params,
      })
      .then((response) => response.payload)
      .catch((error) => {
        if (isAuthError(error)) {
          this.session = null;
          this.username = null;
        }

        throw error;
      });
  }
}

export const gatewayAuthApi = new GatewayAuthApi(webRequestClient, {
  host: environment.API_HOST,
  clientId: environment.AUTH_CLIENT_ID,
});
