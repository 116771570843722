import { useCallback, useEffect } from 'react';
import { Controller, FieldErrors, useForm, Validate } from 'react-hook-form';

import { PhoneType } from '~entities/person';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { NotificationInline } from '@breeze-platform-ui/notification';
import { InputCapitalize, InputPhone, Select } from '@breeze/rhf-adapters';

import { EmploymentStatusValue } from '../../employment-details/lib/employment-status-options';
import {
  REFERENCE_NAME_REQUIRED_ERROR,
  REFERENCE_PHONE_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
} from '../constants/field-errors';
import {
  REFERENCE_FIELD_NAME,
  REFERENCE_NAME_FIELD_NAME,
  REFERENCE_PHONE_FIELD_NAME,
} from '../constants/field-names';
import { getReferenceTypeOptions } from '../lib/get-reference-type-options';
import { shouldAskReferenceName } from '../lib/should-ask-reference-name';
import { WorkReferenceFormValues } from '../model/work-reference';

type Props = Readonly<{
  name: string;
  initialValue?: Partial<WorkReferenceFormValues>;
  employmentStatus: EmploymentStatusValue;
  onPrev?: (
    values: Partial<WorkReferenceFormValues>,
    errors: FieldErrors<WorkReferenceFormValues>
  ) => void;
  onSubmit: (values: WorkReferenceFormValues) => void;
  onFieldCompleted?: (
    value: Partial<WorkReferenceFormValues>,
    errors: FieldErrors<WorkReferenceFormValues>
  ) => void;
  validatePhoneUnicity: (
    phoneType: PhoneType
  ) => Validate<string | undefined, WorkReferenceFormValues>;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
  onClose: () => void;
}>;

export const WorkReference = ({
  name,
  initialValue,
  employmentStatus,
  onFieldCompleted,
  validatePhoneUnicity,
  onPhoneUpdate,
  onSubmit,
  onClose,
  onPrev,
}: Props) => {
  const methods = useForm<WorkReferenceFormValues>({
    mode: 'all',
    defaultValues: initialValue,
    shouldUnregister: true,
  });
  const { control, handleSubmit, getValues, getFieldState, trigger, watch } =
    methods;

  useRevalidate(methods);

  const triggerPhone = useCallback(async () => {
    const value = getValues('workPhone');

    if (getFieldState('workPhone').isDirty || value) {
      const isValid = await trigger('workPhone');

      onPhoneUpdate(isValid ? value : undefined, 'work');
    }
  }, [getFieldState, getValues, trigger, onPhoneUpdate]);

  useEffect(() => {
    triggerPhone();
  }, [triggerPhone, validatePhoneUnicity]);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<WorkReferenceFormValues>(methods);

    onPrev?.(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<WorkReferenceFormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const referenceType = watch('reference');

  const isReferenceNameShown =
    referenceType && shouldAskReferenceName(referenceType);

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={onPrev && handleClickButtonPrev} />}
      >
        <div>
          <ScreenTitle
            title="Work reference"
            subtitle="Must be someone connected with customer’s place of&nbsp;work"
          />
          <NotificationInline
            type="info"
            timer={false}
            showClose={false}
            paddingBottom={20}
          >
            Ask customer to&nbsp;inform this contact that they might get
            a&nbsp;call from&nbsp;us
          </NotificationInline>
          <Controller
            control={control}
            name="reference"
            rules={{
              required: SELECT_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <Select
                label={REFERENCE_FIELD_NAME}
                options={getReferenceTypeOptions(employmentStatus)}
                {...fieldProps}
              />
            )}
          />
          {isReferenceNameShown && (
            <Controller
              name="referenceName"
              control={control}
              shouldUnregister
              rules={{
                validate: combineValidators(
                  validators.required({
                    text: REFERENCE_NAME_REQUIRED_ERROR,
                  }),
                  validators.maxLength({ maxLength: 300 }),
                  validators.filipinoName()
                ),
              }}
              render={(fieldProps) => (
                <InputCapitalize
                  {...fieldProps}
                  label={REFERENCE_NAME_FIELD_NAME}
                />
              )}
            />
          )}
          <Controller
            name="workPhone"
            control={control}
            shouldUnregister
            rules={{
              required: REFERENCE_PHONE_REQUIRED_ERROR,
              validate: combineValidators(
                validators.phoneLength(),
                validators.phoneFormat(),
                validatePhoneUnicity('work')
              ),
            }}
            render={(fieldProps) => (
              <InputPhone
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onBlur: () => {
                    const { error } = fieldProps.fieldState;
                    const { value } = fieldProps.field;

                    onPhoneUpdate(!error ? value : undefined, 'work');
                    fieldProps.field.onBlur();
                  },
                }}
                label={REFERENCE_PHONE_FIELD_NAME}
                type="tel"
              />
            )}
          />
        </div>
      </Screen>
    </form>
  );
};
