import { ApplicationsList, EmptyList } from '~widgets/applications-list';

import { CurrentPosButton } from '~features/current-pos/current-pos';

import { useGetApplicationListQuery } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { NotificationInline } from '@breeze-platform-ui/notification';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import styles from './applications-page.module.css';
import { StartLoanCalculation } from './start-loan-calculation/start-loan-calculation';

import { useApplicationsListPageNavigationConstraints } from '../lib';

export const ApplicationsPage = () => {
  const posId = useAtomValue(selectedPosAtom)?.id;
  const { isLoading, data } = useGetApplicationListQuery(posId);
  const isEmpty = data?.list?.length === 0;

  useApplicationsListPageNavigationConstraints();

  const getContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (isEmpty) {
      return (
        <>
          <EmptyList button={<StartLoanCalculation />} />
        </>
      );
    }

    if (data) {
      return (
        <ApplicationsList applications={data.list} pending={data.pending} />
      );
    }
    return (
      <NotificationInline
        showClose={false}
        type="warning"
        timer={false}
        animateFirstRender={false}
      >
        <Text size={13} bold>
          Something went wrong. Please, try again later.
        </Text>
      </NotificationInline>
    );
  };

  return (
    <>
      <Screen
        header={
          <HeaderWithLogo>
            <CurrentPosButton />
          </HeaderWithLogo>
        }
        footer={<NavigationFooter />}
      >
        {!isEmpty && (
          <>
            <ScreenTitle title="Applications" margin="0 0 16px 8px" />
            <div className={styles.container}>
              <StartLoanCalculation />
            </div>
          </>
        )}
        {getContent()}
      </Screen>
    </>
  );
};
